<template>
  <div v-if="show">
    <div>
      <label for="series" class="block text-sm leading-5 font-medium text-gray-700">Series</label>
      <select :disabled="loading" v-model="filters.series" @change="getAssessments" id="series" class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
        <option value="">Please select a series</option>
        <option v-for="_series in series" :value="_series.id" :key="_series.id">{{ _series.name }}</option>
      </select>
    </div>
    <div class="mt-3">
      <label for="subjects" class="block text-sm leading-5 font-medium text-gray-700">Subject</label>
      <select :disabled="loading" v-model="filters.subject" id="subject" @change="getAssessments" class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
        <option value="">Please select a subject</option>
        <option v-for="subject in subjects" :value="subject.id" :key="subject.id">{{ subject.name }}</option>
      </select>
    </div>

    <div v-if="filters.series && filters.subject && ! loading" class="flex flex-col mt-4">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                Code
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                <!-- -->
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="! assessments.length" class="bg-white">
              <td colspan="20" class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                Sorry, no results found
              </td>
            </tr>
            <tr v-for="assessment in assessments" :key="assessment.id" class="bg-white">
              <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                {{ assessment.title }}
              </td>
              <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                {{ assessment.code }}
              </td>
              <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 capitalize">
                {{ assessment.type }}
              </td>
              <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                <span class="inline-flex rounded-md shadow-sm">
                  <a href="#" @click.prevent="assessmentSelected(assessment)" class="inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                    <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2">Submit Marks</span>
                  </a>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4">
      <loader class="w-5 text-blue-500"></loader> <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  props: ['show'],
  emits: ['selected'],
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      series: [],
      subjects: [],
      assessments: [],
      filters: {
        series: "",
        subject: "",
      },
    }
  },
  mounted() {
    this.getSeries();
    this.getSubjects();
  },
  methods: {
    assessmentSelected(assessment) {
      this.$emit('selected', { assessment, series: this.series.find(s => s.id === this.filters.series) });
    },
    getSubjects() {
      this.loading = true;

      this.$axios.get(this.$global.apiEndpoint('/subjects'))
        .then(response => {
          this.subjects = response.data.subjects;
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSeries() {
      this.loading = true;

      this.$axios.get(this.$global.apiEndpoint('/bookings/series-list?closed=1&hasbookings=1'))
        .then(response => {
          this.series = response.data.series;
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAssessments() {
      if (! this.filters.subject || ! this.filters.series) {
        return;
      }

      this.loading = true;

      this.$axios.post(this.$global.apiEndpoint('/assessments'), { ...this.filters, types: ['endorsement', 'coursework'] })
          .then(response => {
            this.assessments = response.data.assessments;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
}
</script>
