<template>
    <div v-if="user" ref="profile_menu" :class="rtl('mr-3') + ltr('ml-3')" class="relative">
        <div>
            <button @click.prevent="open = !open" class="menu-button max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
                <img aria-label="Your profile image" class="h-8 w-8 rounded-full" :src="icon" alt="Profile Image">
            </button>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="open" :class="rtl('origin-top-left left-0 text-right') + ltr('origin-top-right right-0')" class="absolute mt-2 w-48 rounded-md shadow-lg">
                <div role="menu" class="py-1 rounded-md bg-white shadow-xs">
                    <a aria-label="Log out of this application" role="menuitem" id="logout-button" @click.prevent="logout" href="#" class="logout-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ translation.buttons_links.sign_out }}</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import gravatar from "gravatar";

    export default {
        data() {
            return {
                open: false
            }
        },
        mounted() {
            document.addEventListener('click', this.binding);
        },
        computed: {
            translation() {
                return this.$store.state.translatables.translation;
            },
            user() {
                return this.$store.state.user;
            },
            icon() {
                return gravatar.url(this.user.email);
            },
        },
        methods: {
            binding(evt) {
                evt.stopPropagation();
                if(this.$refs.profile_menu && !this.$refs.profile_menu.contains(evt.target)) {
                    this.open = false;
                }
            },
            logout() {
                this.$emit('logout', true);
            }
        }
    }
</script>
