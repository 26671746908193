<template>
  <teleport to="body">
    <div v-show="open" class="fixed left-0 bottom-0 md:top-0 right-0 px-4 pb-6 sm:p-0 sm:flex sm:items-center sm:justify-center" :class="zIndex">
        <transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="open" class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="duration-200 ease-in"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div v-show="open" :class="[maxWidth, height]" class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:w-full sm:p-6">
                <div class="popup-max-height scrollbar p-1 px-3 flex flex-col justify-between">
                    <slot name="icon"></slot>
                    <div v-if="!hasIconOverride()&&!hide_icon" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 v-if="!hide_title" class="text-lg leading-6 font-medium text-gray-900">{{ title }}</h3>
                        <div :class="{'mt-2': !hide_title}">
                            <p class="text-sm leading-5 text-gray-500">
                                <slot name="content"></slot>
                            </p>
                        </div>
                    </div>
                    <slot name="footer"></slot>
                </div>
            </div>
        </transition>
    </div>
  </teleport>
</template>

<script>
    export default {
        emits: ['state-change', 'cancel'],
        props: {
            open: {
                type: Boolean,
                default: false
            },
            hide_icon: {
                type: Boolean,
                default: false
            },
            hide_title: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            maxWidth: {
                type: String,
                default: 'sm:max-w-lg'
            },
            height: {
                type: String,
                default: '',
            },
            zIndex: {
                type: String,
                default: 'z-40',
            },
        },
        watch: {
            open(newVal) {
                this.$emit('state-change', newVal);
            }
        },
        data() {
            return {

            }
        },
        methods: {
            cancel() {
                this.$emit('cancel');
            },
            hasIconOverride() {
                return !! this.$slots.icon;
            }
        }
    }
</script>
