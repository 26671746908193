<template>
    <slide-over :open="open" :title="'My messages'" @changed="changed">
        <template v-slot:contents>
            <div v-if="!notifications.length">
                <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p class="text-sm leading-5 text-yellow-700">
                                You currently have no notifications to display.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="notification in notifications" :key="notification.id">
                <div class="flex items-end justify-center px-1 py-4 pointer-events-none sm:items-start sm:justify-end w-full">
                    <div :class="{'shadow-lg': ! notification.read, 'opacity-75': notification.read }" class="w-full rounded-lg pointer-events-auto border border-gray-100">
                        <div class="rounded-lg shadow-xs overflow-hidden">
                            <div class="p-4">
                                <div class="flex items-start">
                                    <div class="flex-shrink-0">
                                        <svg v-if="notification.type === 'success'" class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <svg v-else-if="notification.type === 'error'" class="w-6 h-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                        <svg v-else class="w-6 h-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                    </div>
                                    <div class="ml-3 w-0 flex-1 pt-0.5">
                                        <p class="text-sm leading-5 font-medium text-gray-900">
                                            <span v-if="notification.type === 'success'" class="text-green-400">Success</span>
                                            <span v-else-if="notification.type === 'error'" class="text-red-400">Error</span>
                                            <span v-else class="text-blue-400">Info</span>
                                        </p>
                                        <p class="mt-1 text-sm leading-5 text-gray-500">
                                            {{ notification.message }}
                                        </p>
                                        <div class="mt-2">
                                            <button @click.prevent="markAsRead(notification)" v-if="! notification.read" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                                Mark as read
                                            </button>
                                            <button @click.prevent="deleteNotification(notification)" :class="{'ml-6': ! notification.read}" class="text-sm leading-5 font-medium text-red-500 hover:text-red-700 focus:outline-none focus:underline transition ease-in-out duration-150">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                    <div class="ml-4 flex-shrink-0 flex text-xs text-gray-500">
                                        {{ format(notification.created_at) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </slide-over>
</template>

<script>
import SlideOver from "@/components/SlideOver";
import moment from "moment";

let interval = null;

export default {
    emits: ['changed', 'notifications'],
    components: {
        SlideOver
    },
    props: {
        open: {
            default: false,
            type: Boolean
        }
    },
    mounted() {
        if(interval === null) {
            this.getNotifications();
            interval = setInterval(() => {
                this.getNotifications();
            }, 20000);
        }
    },
    computed: {
        notifications() {
            return this.$store.state.messages;
        }
    },
    methods: {
        getNotifications() {
            this.$axios.get(this.$global.apiEndpoint('/notification/all'))
                .then(response => {
                    this.$store.commit('setMessages', response.data);
                })
                .catch(() => {});
        },
        changed(value) {
            this.$emit('changed', value);
        },
        format(dateTime) {
            return moment(dateTime).format('DD/MM/YYYY HH:mm');
        },
        deleteNotification(notification) {
            this.$axios.post(this.$global.apiEndpoint(`/notification/${notification.id}/delete`), {})
                .then(() => {
                    this.$store.commit('removeMessage', notification);
                });
        },
        markAsRead(notification) {
            notification.read = 1;
            this.$axios.post(this.$global.apiEndpoint(`/notification/${notification.id}/mark-as-read`), {});
        }
    }
}
</script>
