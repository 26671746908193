import { createStore } from 'vuex'
import VuexPersist from "vuex-persistedstate";
import SecureLS from "secure-ls";

const storageKey = 'mod-booking-client';
const ls = new SecureLS({ isCompression: false });

const PersistentStorage = new VuexPersist({
  key: storageKey,
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  }
});

export default createStore({
  plugins: [PersistentStorage],
  state: {
    logged_in_at: null,
    user: null,
    token: null,
    page_loading: false,
    notifications: [],
    messages: [],
    translatables: {
      translation: {
        headings: {},
        labels: {},
        buttons_links: {},
        messages: {
          terms: {
          }
        }
      },
      locale: 'en',
    }
  },
  mutations: {
    setUser(state, object) {
      state.user = object;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPageLoading(state, boolean) {
      state.page_loading = boolean;
    },
    setLoggedInAtTime(state, time) {
      state.logged_in_at = time;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    addMessage(state, message) {
      state.messages.push(message);
    },
    removeMessage(state, message) {
      state.messages.splice(state.messages.indexOf(message), 1);
    },
    addNotification(state, notification) {
      state.notifications.push(Object.assign(
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            type: 'info',
            persistent: false,
            heading: 'Info',
            message: '',
            notified: false
          },
          notification
      ));
    },
    removeNotification(state, notification) {
      state.notifications.splice(state.notifications.indexOf(notification), 1);
    },
    setTranslatables(state, data) {
      state.translatables = data;
    },
    clear(state) {
      state.logged_in_at = null;
      state.user = null;
      state.notifications = [];
      state.messages = [];
    }
  },
  actions: {
  },
  modules: {
  }
})
