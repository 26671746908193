<template>
  <div class="mb-3 sm:p-2 sm:rounded-md sm:bg-gray-100">
    <label class="sr-only">Search</label>
    <div class="flex rounded-md shadow-sm">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-gray-400">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
          </svg>
        </div>
        <input @keyup="searchKeyUpHandler" v-model="filters.search" :disabled="loading" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none pl-10 sm:text-sm border-gray-200 text-gray-900" placeholder="Search candidates">
      </div>
      <div class="relative focus-within:z-10">
        <select @change="filter" v-model="filters.sort" :disabled="loading" class="bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-l-1 text-gray-900 sm:text-sm rounded-none rounded-r-md">
          <option value="">Sort</option>
          <option value="name_asc">Name - ascending</option>
          <option value="name_desc">Name - descending</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['filter'],
  props: ['loading'],
  data() {
    return {
      filters: {
        search: '',
        sort: '',
      },
      debounce: null,
    }
  },
  methods: {
    searchKeyUpHandler() {
      if (this.debounce !== null) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.filter();
      }, 700);
    },
    filter() {
      this.$emit('filter', this.filters);
    }
  }
}
</script>
