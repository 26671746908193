<template>
  <popup :open="open" title="Select Candidates" :hide_icon="true">
    <template v-slot:content>
      <div class="bg-white">
        <div role="alert" aria-live="polite">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

            <p v-if="errors.global" class="text-red-500">{{ errors.global }}</p>

            <Loader v-if="loading" class="mt-5 w-10 mx-auto"></Loader>

            <div v-else>
              <p v-if="candidate_sessions.length === 0">Sorry, no results found</p>

              <div v-else>
                <div class="flex items-center font-semibold mb-3">
                  <input type="checkbox" @change="toggleAll" id="toggle-all" />
                  <label for="toggle-all" class="ml-4">Toggle all</label>
                </div>

                <ul class="overflow-y-scroll max-h-60 shadow-inner px-4">
                  <li v-for="(session, idx) in candidate_sessions" :key="idx" class="my-2 font-medium flex items-center" :class="canDownload(session) ? 'text-indigo-500' : 'text-gray-400'">
                    <input v-if="canDownload(session)" v-model="checked" :value="session.id" type="checkbox" :id="`select-candidate-${idx}`" />
                    <div v-else class="w-4 h-1 block"></div>
                    <label :for="`select-candidate-${idx}`" class="ml-4">{{ session.candidate.full_name }}</label>
                    <span :class="sessionClass(session)" class="px-2 inline-flex ml-auto ml-1 text-xs leading-5 font-semibold rounded-full">
                      {{ sessionStatus(session) }}
                    </span>
                    <span v-if="canDownload(session)" class="inline-flex rounded-md shadow-sm">
                      <a href="#" @click.prevent="reviewScript(session)" class="inline-flex items-center px-1.5 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150 ml-1">
                        Review
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span v-show="! loading && candidate_sessions.length" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-2">
          <button @click.prevent="registerCandidates" :disabled="! checked.length || processing" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
            <svg class="-ml-1 mr-3 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd"></path>
            </svg>
            <Loader v-if="processing" class="w-6" />
            <span v-else>Download Selected</span>
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button @click.prevent="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
import Loader from "./Loader";
import Popup from "./Popup";

export default {
  emits: ['close', 'review'],
  components: {
    Popup,
    Loader,
  },
  data() {
    return {
      checked: [],
      candidate_sessions: [],
      loading: false,
      processing: false,
      errors: {
        global: '',
      },
    };
  },
  props: {
    open: {
      default: false,
      type: Boolean
    },
    scheduleId: {
      type: String,
    },
  },
  computed: {
    payload() {
      return {
        candidate_sessions: this.checked,
      };
    },
  },
  watch: {
    open: function () {
      if (this.open) {
        this.get();
      } else {
        this.checked = [];
        this.candidate_sessions = [];
      }
    }
  },
  methods: {
    get() {
      this.loading = true;

      this.setXSRFToken(() => {
        this.$axios.get(this.$global.apiEndpoint(`/schedules/${this.scheduleId}/candidate-sesssions`))
            .then(response => {
              this.candidate_sessions = response.data.candidate_sessions;
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
            })
            .finally(() => {
              this.loading = false;
            });
      });
    },
    canDownload(session) {
      return session.submitted_at !== null
          || (session.media === 'paper' && session.paperAvailable && session.paperAvailable !== null);
    },
    sessionClass(session) {
      return this.canDownload(session) ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800';
    },
    sessionStatus(session) {
      return this.canDownload(session) ? 'Available' : 'Unavailable';
    },
    close() {
      this.$emit('close');
    },
    toggleAll(e) {
      this.checked = [];

      if (e.target.checked) {
        this.candidate_sessions.forEach(session => this.canDownload(session) && this.checked.push(session.id));
      }
    },
    registerCandidates() {
      this.processing = true;

      this.$axios.post(this.$global.apiEndpoint(`/schedules/${this.scheduleId}/download-scripts`), this.payload)
        .then(() => {
          this.$global.success('You will receive an email when the export is ready', 'Processing');
          this.close();
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    reviewScript(session) {
      this.$emit('review', session);
    }
  }
}
</script>
