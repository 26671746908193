<template>
    <layout>
        <template v-slot:contents>
            <div id="bookings" v-cloak class="flex flex-col">
                <div class="pb-5 flex flex-col">
                    <div class="mb-3 sm:p-2 sm:rounded-md sm:bg-gray-100">
                        <label class="sr-only">Search</label>
                        <div class="flex rounded-md shadow-sm">
                            <div class="relative focus-within:z-10">
                                <select @change="paginate(1)" v-model="filter.series" :disabled="processing || loading" aria-label="Series" class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none rounded-l-md">
                                    <option value="">All series</option>
                                    <option v-for="_series in series" :value="_series.code" :key="_series.code">{{ _series.name }}</option>
                                </select>
                            </div>
                            <div class="relative focus-within:z-10">
                                <select @change="paginate(1)" v-model="filter.subject" :disabled="processing || loading" aria-label="Subject" class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none">
                                    <option value="">All Subjects</option>
                                    <option v-for="_subject in subjects" :value="_subject.code" :key="_subject.code">{{ _subject.name }}</option>
                                </select>
                            </div>
                            <div class="relative focus-within:z-10" v-if="subjectOptions && subjectOptions.length">
                                <select @change="paginate(1)" v-model="filter.subjectOption" :disabled="processing || loading" aria-label="Subject Option" class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none">
                                    <option value="">All Options</option>
                                    <option v-for="_option in subjectOptions" :value="_option.code" :key="_option.code">{{ _option.title }}</option>
                                </select>
                            </div>
                            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-gray-400">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <input @keyup="searchKeyUpHandler" v-model="filter.search" :disabled="processing || loading" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none pl-10 sm:text-sm border-gray-200 text-gray-900" placeholder="Search candidates">
                            </div>
                            <div class="relative focus-within:z-10">
                                <select @change="paginate(pagination.current_page)" v-model="filter.sort" :disabled="processing || loading" class="bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-l-1 text-gray-900 sm:text-sm rounded-none rounded-r-md">
                                    <option value="">Sort</option>
                                    <option value="starts_asc">Starts - ascending</option>
                                    <option value="starts_desc">Starts - descending</option>
                                    <option value="name_asc">Name - ascending</option>
                                    <option value="name_desc">Name - descending</option>
                                </select>
                            </div>
                        </div>
                    </div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900 flex items-center">
                      <span class="block focus-within:ring-2 focus-within:ring-indigo-500 rounded-md flex ease-in-out duration-150">
                        <span class="inline-flex items-center border rounded-l-md">
                        <select :disabled="processing || loading" v-model="action" aria-label="Country" class="text-gray-900 focus:outline-none focus:ring-0 border-none relative block w-full rounded-none rounded-l-md bg-white transition sm:text-sm sm:leading-4">
                          <option value="">Please select an action</option>
                          <option value="preDelete">Delete selected</option>
                        </select>
                      </span>
                      <button :disabled="processing || loading" @click.prevent="doAction" class="-ml-px focus:outline-none focus:ring-0 relative inline-flex items-center px-4 py-1.5 border border-blue-500 text-sm leading-4 font-medium rounded-r-md text-white bg-blue-500 hover:text-gray-50 hover:bg-blue-400 transition ease-in-out duration-150">
                        <loader v-if="processing" class="w-5 h-5"></loader>
                        <svg v-else class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                        </svg>
                      </button>
                      </span>
                  </h3>
                </div>
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 relative">
                    <div :class="{'pb-40': bookings.length}" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr>
                                <th class="pl-6 py-3 border-b border-gray-200 bg-gray-50">
                                    <div class="relative flex items-start">
                                        <input @change="selectAllHandler" type="checkbox" class="h-4 w-4 text-blue-500 transition duration-150 ease-in-out rounded">
                                    </div>
                                </th>
                                <th width="200" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Candidate
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Assessment
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Subject Code
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Paper Code
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Starts/<br/>Ends
                                </th>
                                <th width="120" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Extra Time
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-if="!bookings.length && ! loading">
                                <td colspan="20" class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="rounded-md bg-blue-50 p-4">
                                        <div class="flex">
                                            <div class="flex-shrink-0">
                                                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <div class="ml-3 flex-1 md:flex md:justify-between items-center">
                                                <p class="text-sm leading-5 text-blue-700">
                                                    You currently have no candidates booked on any assessments. <br/>Please use the import tool on the dashboard to import your candidates.
                                                </p>
                                                <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6 flex-shrink-0">
                                                    <a href="/" class="whitespace-no-wrap font-medium text-blue-700 hover:text-blue-600 transition ease-in-out duration-150">
                                                        Import candidates &rarr;
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(booking, index) in bookings" :key="index" :class="{'bg-white': index % 2 === 0, 'bg-gray-50': index % 2 !== 0 }">
                                <td class="pl-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="relative flex items-start">
                                        <input v-model="booking.selected" type="checkbox" :disabled="!windowIsOpen(booking)" class="h-4 w-4 text-blue-500 transition duration-150 ease-in-out rounded">
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="flex items-center">
                                        <div class="">
                                            <div class="text-sm leading-5 font-medium text-gray-900">
                                                {{ booking.candidate.forename }} {{ booking.candidate.surname }}
                                            </div>
                                            <div class="text-xs leading-5 text-gray-500">
                                                {{ booking.candidate.username ?? '-' }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-900">{{ booking.group.assessment_schedule.assessment.title }}</div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-900">{{ booking.group.assessment_schedule.assessment.subject.code }}</div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-900">{{ booking.group.assessment_schedule.assessment.code }}</div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="text-xs leading-5 text-gray-500">{{ format(booking.group.assessment_schedule.window_starts_at) }}</div>
                                    <div class="text-xs leading-5 text-gray-500">{{ format(booking.group.assessment_schedule.window_ends_at) }}</div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div class="text-xs leading-5 text-gray-500">
                                      {{ booking.extra_time }} minutes

                                      <span v-if="booking.paao_reference">PAAO Ref: {{ booking.paao_reference }}</span>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <span :class="bookingStatusClasses(booking)" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                                        <span v-if="booking.window_status === 'complete' && !booking.submitted_at">not submitted</span>
                                        <span v-else>{{ booking.window_status }}</span>
                                    </span>
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                    <div class="booking-list-menu">
                                        <list-menu>
                                            <template v-slot:content>
                                                <a @click.prevent="extraTimeClickHandler(booking)" href="#" :class="{'opacity-25': !windowIsOpen(booking)}" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                                                    <svg v-if="windowIsOpen(booking)" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg>
                                                    <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                                    </svg>
                                                    Extra Time
                                                </a>
                                                <a @click.prevent="editCandidateClickHandler(booking)" href="#" :class="{'opacity-25': !windowIsOpen(booking)}" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                                                    <svg v-if="windowIsOpen(booking)" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                                    </svg>
                                                    Edit Candidate
                                                </a>
                                                <a v-if="booking.subject_option_id" @click.prevent="editBookingClickHandler(booking)" href="#" :class="{'opacity-25': !windowIsOpen(booking)}" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                                                    <svg v-if="windowIsOpen(booking)" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                                    </svg>
                                                    Edit Booking
                                                </a>
                                                <a @click.prevent="deleteClickHandler(booking)" href="#" :class="{'opacity-25': !windowIsOpen(booking)}" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                                                    <svg v-if="windowIsOpen(booking)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500">
                                                        <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                                    </svg>
                                                    <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                                    </svg>
                                                    Delete
                                                </a>
                                            </template>
                                        </list-menu>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="!loading" class="mt-8">
                        <pagination
                            @paginate="paginate"
                            :collection-name="'Bookings'"
                            :prev-page="prevPage"
                            :next-page="nextPage"
                            :page-links="pageLinks"
                            :to="pagination.showing.to"
                            :from="pagination.showing.from"
                            :of="pagination.showing.of"
                            :current="pagination.current_page"
                        >
                        </pagination>
                    </div>
                </div>
                <div v-if="loading" class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4">
                    <loader class="w-5 text-blue-500"></loader> <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
                </div>
            </div>
        </template>
    </layout>
    <add-extra-time-to-booking
        @close="popups.add_extra_time_to_booking = {open: false, booking: null}"
        @updated="updateExtraTime"
        :open="popups.add_extra_time_to_booking.open"
        :booking="popups.add_extra_time_to_booking.booking"
    ></add-extra-time-to-booking>
    <delete-booking
        @close="popups.delete_booking = {open: false, bookings: []}"
        @deleted="deletedBookings"
        :open="popups.delete_booking.open"
        :bookings="popups.delete_booking.bookings"
    ></delete-booking>
    <edit-candidate
        @close="popups.edit_candidate = {open: false, booking: null}"
        @updated="updateCandidateEdit"
        :open="popups.edit_candidate.open"
        :booking="popups.edit_candidate.booking"
    ></edit-candidate>
    <edit-booking
        @close="popups.edit_booking = {open: false, booking: null}"
        @updated="updateBookingEdit"
        :open="popups.edit_booking.open"
        :booking="popups.edit_booking.booking"
    ></edit-booking>
</template>

<script>
import Layout from "@/components/Layout";
import Pagination from "@/components/Pagination";
import Loader from "@/components/Loader";
import ListMenu from "@/components/ListMenu";
import AddExtraTimeToBooking from "@/components/AddExtraTimeToBooking";
import DeleteBooking from "@/components/DeleteBooking";
import EditCandidate from "@/components/EditCandidate";
import EditBooking from "@/components/EditBooking";

export default {
    name: 'Bookings',
    beforeRouteEnter(to, from, next) {
        window.bus.$gates.home(to, from, next);
    },
    components: {
        Layout,
        Pagination,
        Loader,
        ListMenu,
        AddExtraTimeToBooking,
        DeleteBooking,
        EditCandidate,
        EditBooking
    },
    data() {
        return {
            checking: false,
            action: "",
            processing: false,
            bookings: [],
            loading: false,
            series: [],
            subjects: [],
            subjectOptions: [],
            selected_series: "",
            searching: null,
            filter: {
              series: "",
              search: "",
              sort: "",
              subject: "",
              subjectOption: "",
            },
            pagination: {
                showing: {
                    from: 0,
                    to: 0,
                    of: 0
                },
                current_page: 1,
                last_page: 1
            },
            popups: {
                add_extra_time_to_booking: {
                    open: false,
                    booking: null
                },
                delete_booking: {
                    open: false,
                    bookings: []
                },
                edit_candidate: {
                    open: false,
                    booking: null
                },
                edit_booking: {
                    open: false,
                    booking: null
                },
            }
        };
    },
    computed: {
        pageLinks() {
            let links = [];
            for(let i = 1; i <= this.pagination.last_page; i++) {
                links.push(i);
            }
            return links;
        },
        prevPage() {
            return this.pagination.current_page - 1;
        },
        nextPage() {
            return (this.pagination.current_page + 1) > parseInt(this.pagination.last_page) ? 0 : this.pagination.current_page + 1;
        }
    },
    mounted() {
        this.setDataFromQueryStringParams();
        this.get();
    },
    methods: {
        bookingStatusClasses(booking) {
            if(booking.window_status === 'active') {
                return 'bg-green-100 text-green-800';
            }
            if( booking.window_status === 'upcoming') {
                return 'bg-yellow-100 text-yellow-800';
            }
            if(booking.window_status === 'complete') {
                return 'bg-red-100 text-red-800';
            }
        },
        setDataFromQueryStringParams() {
            let params = this.$global.getParamsFromQueryString();
            if(params.page) this.pagination.current_page = decodeURIComponent(params.page);
            if(params.query) this.filter.search = decodeURIComponent(params.query);
            if(params.series) this.filter.series = decodeURIComponent(params.series);
            if(params.subject) this.filter.subject = decodeURIComponent(params.subject);
            if(params.subjectOption) this.filter.subjectOption = decodeURIComponent(params.subjectOption);
            if(params.order_by) this.filter.sort = decodeURIComponent(params.order_by);
        },
        updateExtraTime(data) {
            this.popups.add_extra_time_to_booking.booking.extra_time = data.minutes;
            this.popups.add_extra_time_to_booking.booking.paao_reference = data.paaoRef;
        },
        deletedBookings(ids) {
            this.bookings = this.bookings.filter(booking => ! ids.includes(booking.id));
        },
        updateBookingEdit() {
            this.bookings = [];
            this.get();
        },
        updateCandidateEdit(values) {
            this.bookings.forEach(booking => {
                if (booking.candidate.external_id === values.candidate_id) {
                    booking.candidate.forename = values.forename;
                    booking.candidate.surname = values.surname;
                    booking.candidate.gender = values.gender;
                    booking.candidate.email = values.email;
                    booking.candidate.dob = values.dob;
                }
            })
        },
        get() {
            this.loading = true;
            this.$axios.post(this.$global.apiEndpoint('/bookings/get'), {
              page: this.pagination.current_page,
              query: this.filter.search,
              series: this.filter.series,
              subject: this.filter.subject,
              subjectOption: this.filter.subjectOption,
              order_by: this.filter.sort
            })
                .then(response => {
                    this.bookings = response.data.bookings.data;
                    this.series = response.data.series;
                    this.subjects = response.data.subjects;
                    this.subjectOptions = response.data.subjectOptions;
                    this.bookings.forEach(booking => booking.selected = false);
                    this.pagination = this.$global.paginationData(response, 'bookings');
                    this.republishQueryParameters();
                    this.loading = false;
                    window.scrollTo(0,0);
                })
                .catch(error => {
                    this.$global.globalAXIOSErrorHandler(error);
                });
        },
        format(dateTime) {
            return this.$moment(dateTime).format("DD/MM/YYYY");
        },
        paginate(page_number) {
            this.bookings = [];
            this.pagination.current_page = page_number;
            this.get();
        },
        deleteClickHandler(booking) {
            if(!this.windowIsOpen(booking)) return;
            this.$global.closeAllMenus();
            this.popups.delete_booking = {
                open: true,
                bookings: [booking]
            }
        },
        editCandidateClickHandler(booking) {
            if(!this.windowIsOpen(booking)) return;
            this.$global.closeAllMenus();
            this.popups.edit_candidate = {
                open: true,
                booking
            }
        },
        editBookingClickHandler(booking) {
            if (!this.windowIsOpen(booking)) return;
            this.$global.closeAllMenus();
            this.popups.edit_booking = {
                open: true,
                booking
            }
        },
        windowIsOpen(booking) {
            if(!booking.group.assessment_schedule.booking_option) {
                return true;
            }
            return booking.group.assessment_schedule.booking_option.window_open;
        },
        extraTimeClickHandler(booking) {
            if(!this.windowIsOpen(booking)) return;
            this.$global.closeAllMenus();
            this.popups.add_extra_time_to_booking = {
                open: true,
                booking
            }
        },
        doAction() {
            let bookings = this.getSelectedBookings();
            let bookingIds = bookings.map(booking => booking.id);

            if(! bookingIds.length) {
                return this.$global.error('No bookings selected');
            }

            if(!this.action) {
                return this.$global.error('No action selected');
            }

            if(this.action === 'preDelete') {
                this.popups.delete_booking = {open: true, bookings};
                return;
            }

            this.processing = true;
            this.$axios.post(this.$global.apiEndpoint(`/bookings/actions`), {action: this.action, ids: bookingIds})
                .then(response => {
                    this.processing = false;
                    this.$global.success(response.data.message);
                    this.storeNewBookingsData(response.data.data);
                    this.republishQueryParameters();
                })
                .catch(error => {
                    this.processing = false;
                    this.$global.globalAXIOSErrorHandler(error);
                });
        },
        storeNewBookingsData(data) {
            this.pagination.showing = {
                from: data.from,
                to: data.to,
                of: data.total,
            };
            this.pagination.current_page = data.current_page;
            this.pagination.last_page = data.last_page;

            let bookings = data.data;
            bookings.forEach(booking => booking.selected = false);
            this.bookings = bookings;

            this.$nextTick(() => {
                window.scrollTo(0,0);
            });
        },
        selectAllHandler(e) {
            this.bookings.forEach(booking => {
                if(!this.windowIsOpen(booking)){
                    return;
                }
                booking.selected = e.target.checked
            });
        },
        searchKeyUpHandler() {
            if(this.searching !== null) {
                clearTimeout(this.searching);
            }
            this.searching = setTimeout(() => {
                this.paginate(1);
            }, 700);
        },
        republishQueryParameters() {
            if (history.pushState) {
                let newURL = window.location.protocol + "//" + window.location.host + window.location.pathname + `?page=${this.pagination.current_page}&query=${this.filter.search}&series=${this.filter.series}&subject=${this.filter.subject}&subjectOption=${this.filter.subjectOption}&order_by=${this.filter.sort}`;
                window.history.pushState({path:newURL}, '', newURL);
            }
        },
        getSelectedBookings() {
            return this.bookings.filter(booking => booking.selected === true);
        }
    }
}
</script>
