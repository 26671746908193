<template>
  <popup
      :open="open"
      :title="'Edit Booking'"
      @cancel="close"
      @state-change="stateChange"
  >
    <template v-slot:icon>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div class="flex flex-row space-x-3">
        <div class="flex-1">
          <div class="text-left mt-6">
            <label class="block text-sm font-medium leading-5 text-gray-700">Subject Code</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input :value="values.subject_code" :disabled="true" type="text" class="block w-full sm:text-sm sm:leading-5 rounded-md border-gray-300"  >
            </div>
          </div>

          <div class="text-left mt-6">
            <label class="block text-sm font-medium leading-5 text-gray-700">Current Subject Option</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input :value="values.subject_option_title" :disabled="true" type="text" class="block w-full sm:text-sm sm:leading-5 rounded-md border-gray-300"  >
            </div>
          </div>
          <div class="text-left mt-6">
            <div class="rounded-md bg-yellow-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3 text-sm leading-5 text-yellow-700">
                  Changing a subject option will delete all bookings for the subject option and book the candidate onto the new option.
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" class="flex justify-center space-x-3 bg-blue-100 rounded-md py-1 mt-2"><loader class="w-5 h-5 mr-3"></loader> Loading...</div>
          <div v-else class="text-left mt-6">
            <label class="block text-sm font-medium leading-5 text-gray-700">New Subject Option</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <select :disabled="loading" v-model="values.subject_option" class="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-gray-300">
                <option v-if="loading" value="">Loading...</option>
                <option v-for="subjectOption in subjectOptions" :key="subjectOption.id" :value="subjectOption.id" :disabled="booking && (booking.subject_option.id == subjectOption.id)">
                  {{ subjectOption.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button :disabled="processing" @click.prevent="saveBooking" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>Save</span>
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button :disabled="processing" @click.prevent="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Close
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
  import Loader from "@/components/Loader";
  import Popup from "@/components/Popup";

  export default {
    emits: ['close', 'updated'],
    components: {
      Loader,
      Popup
    },
    props: {
      booking: {
        type: Object,
        default: null
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      open() {
        if (this.open) {
          this.getSubjectOptions();
        }
      },
    },
    computed: {
      subject() {
        return this.booking.group.assessment_schedule.assessment.subject;
      },
    },
    data() {
      return {
        loading: false,
        processing: false,
        subjectOptions: [],
        values: {
          subject_option: '',
          candidate_id: '',
          subject_option_title: '',
          subject_code: '',
        }
      };
    },
    methods: {
      getSubjectOptions() {
        this.loading = true;

        this.$axios.get(this.$global.apiEndpoint(`/subjects/${this.subject.id}/options`))
          .then(result => {
            this.subjectOptions = result.data.options;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          })
      },
      stateChange(state) {
        this.$nextTick(() => {
          if(state) {
            this.values = {
              candidate_id: this.booking.candidate.external_id,
              subject_option: this.booking.subject_option.id,
              subject_code: this.booking.group.assessment_schedule.assessment.subject.code,
              subject_option_title: this.booking.subject_option.title,
            };
          }
        });
      },
      close() {
        this.$emit('close');
      },
      saveBooking() {
        this.processing = true;
        this.$axios.patch(this.$global.apiEndpoint(`/bookings/${this.booking.id}/option`), this.values)
          .then(response => {
            this.processing = false;
            this.$emit('updated');
            this.close();
            this.$global.success(response.data);
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
            this.processing = false;
          });
      },
    }
  }
</script>
