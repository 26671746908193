<template>
    <div @keyup.esc="open = false" class="relative inline-block text-left">
        <div>
            <button @click.prevent="toggle" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
            </button>
        </div>
        <transition
            enter-active-class="duration-150 ease-out"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
            >
            <div v-show="open" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-20">
                <div class="rounded-md bg-white shadow-xs">
                    <div class="py-1">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                open: false,
            };
        },
        methods: {
            toggle() {
                this.open = ! this.open;
            }
        },
        mounted() {
            document.addEventListener('click', evt => {
                evt.stopPropagation();
                if(!this.$el.contains(evt.target)) {
                    this.open = false;
                }
            });
            document.addEventListener('close-all-menus', () => {
                this.open = false;
            });
        }
    }
</script>
