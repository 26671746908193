<template>
  <div>
    <h2 class="mb-2 font-medium">Details</h2>

    <div class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr>
          <th class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Code
          </th>
          <th class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Title
          </th>
          <th v-if="series" class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Series
          </th>
          <th v-if="assessment.type === 'coursework'" class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Max Mark
          </th>
          <th class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Type
          </th>
          <th class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Candidates
          </th>
          <th class="px-6 py-2 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
            Unmarked
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="bg-white">
          <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ assessment.code }}
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ assessment.title }}
          </td>
          <td v-if="series" class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ series.name }}
          </td>
          <td v-if="assessment.type === 'coursework'" class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ assessment.max_mark }}
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900 capitalize">
            {{ assessment.type }}
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ totalCount }}
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
            {{ unmarkedCount }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['unmarkedCount', 'totalCount', 'assessment', 'series'],
}
</script>
