<template>
  <div>
    <layout>
      <template v-slot:contents>
        <div class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
              {{ translation().headings.forgotten_password }}
            </h1>
          </div>
          <form ref="form" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div :class="rtl('text-right')" class="lg:bg-white py-8 px-4 lg:shadow sm:rounded-lg sm:px-10">
              <div v-if="message" class="rounded-md bg-green-50 p-4 mb-6">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                      <path clip-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            fill-rule="evenodd"/>
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm leading-5 font-medium text-green-800">
                      Success
                    </h3>
                    <div class="mt-2 text-sm leading-5 text-green-700">
                      <p>
                        {{ message }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="errors.global" class="text-red-500 text-sm mb-6">{{ errors.global }}</div>
              <div>
                <label class="block text-sm font-medium leading-5 text-gray-700" for="email">
                  {{ translation().labels.email }}
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="email" v-model="email" :class="rtl('text-right')" :disabled="processing" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-2 focus:ring-indigo-300" required type="text">
                </div>
              </div>
              <div class="mt-6">
                <label class="block text-sm font-medium leading-5 text-gray-700" for="school_id">
                  {{ translation().labels.school_id }}
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input id="school_id" v-model="school_id" :class="rtl('text-right')" :disabled="processing" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-2 focus:ring-indigo-300" required type="text">
                </div>
              </div>
              <div class="mt-6 flex items-center justify-between">
                <div class="text-sm leading-5 ml-auto">
                  <a :class="{'pointer-events-none select-none': processing}"
                     class="underline font-medium text-indigo-800 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-800 focus:no-underline rounded"
                     href="#" @click.prevent="login">
                    {{ translation().buttons_links.login }}
                  </a>
                </div>
              </div>
              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                    <button :disabled="processing"
                            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out focus:ring-2 focus:ring-indigo-300"
                            test-ref="send-reset-link" type="submit" @click.prevent="sendOutEmail">
                        <loader v-if="processing" class="w-5 h-5"></loader>
                        <span v-else>{{ translation().buttons_links.send_reset_link }}</span>
                    </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

export default {
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.forgottenPassword(to, from, next);
  },
  components: {
    Layout,
    Loader
  },
  data() {
    return {
      processing: false,
      email: '',
      school_id: '',
      message: '',
      errors: {
        global: '',
        email: '',
        school_id: '',
      }
    };
  },
  methods: {
    login() {
      this.$router.push('/');
    },
    setXSRFToken(callback) {
      this.$axios.get(this.$global.endpoint('/sanctum/csrf-cookie'))
          .then(callback);
    },
    payload() {
      return {
        locale: this.$store.state.translatables.locale,
        email: this.email,
        school_id: this.school_id,
        device_name: window.WURFL ? window.WURFL.complete_device_name : 'undefined'
      }
    },
    register() {
      this.$router.push('/register');
    },
    sendOutEmail() {
      this.clearErrors();
      if (!this.$refs.form.checkValidity()) {
        return this.$refs.form.reportValidity();
      }
      this.processing = true;
      this.setXSRFToken(() => {
        this.$axios.post(this.$global.endpoint('/sanctum/users/password/email'), this.payload())
            .then(response => {
              this.message = response.data.message;
              this.processing = false;
              this.clearForm();
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error, this);
              this.processing = false;
            });
      });
    },
    clearErrors() {
      this.errors = {
        global: '',
        email: '',
        school_id: '',
      }
    },
    clearForm() {
      this.email = '';
      this.school_id = '';
    }
  }
}
</script>
