<template>
  <div>
    <nav class="sm:hidden mb-8 bt-6">
      <a href="#" @click.prevent="returnToListings" class="flex items-center text-sm leading-5 font-medium text-blue-700 hover:text-gray-700 transition duration-150 ease-in-out">
        <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
        Back
      </a>
    </nav>
    <nav class="hidden sm:flex items-center text-sm leading-5 font-medium mb-8 bt-6">
      <a href="#" @click.prevent="returnToListings" class="text-gray-800 underline hover:text-gray-700 transition duration-150 ease-in-out">Schedule Listings</a>
      <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
      <span class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Import Highstakes</span>
    </nav>

    <div class="bg-white shadow border border-gray-200 overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:p-0">
        <dl>
          <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Import File
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <label class="relative overflow-hidden border border-gray-200 cursor-pointer rounded-md pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 hover:bg-gray-50 transition-colors duration-200 ease-in-out">
                <span class="w-0 flex-1 flex items-center">
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-2 flex-1 w-0 truncate">
                    <span v-if="file">{{ file.name }}</span>
                    <span v-else>click to attach candidates import file...</span>
                  </span>
                </span>
                <input @change="fileChange" :disabled="importing" ref="upload" type="file" name="file" class="absolute w-1 h-1 top-0 left-0 -mt-8">
              </label>
            </dd>
          </div>
          <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dd class="sm:col-start-1 flex">
              <span class="inline-flex rounded-md shadow-sm mt-1">
                <button @click.prevent="downloadTemplate" :disabled="exporting" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                  <svg class="-ml-1 mr-3 h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clip-rule="evenodd"></path></svg>
                  <loader v-if="exporting" class="w-5 text-white"></loader>
                  <span v-else>Download Template</span>
                </button>
              </span>
              <span class="inline-flex rounded-md shadow-sm mt-1 ml-3">
                <button @click.prevent="importFile" :disabled="importing" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                  <loader v-if="importing" class="-ml-1 mr-3 w-5 text-white"></loader>
                  <svg v-else class="-ml-1 mr-3 h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Import Candidates <span v-if="importing">{{ progress }}%</span></span>
                </button>
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  props: ['exporting', 'seriesCode', 'bookingOptionId'],
  emits: ['close', 'downloadTemplate'],
  components: {
    Loader,
  },
  data() {
    return {
      importing: false,
      progress: 0,
      file: null,
    };
  },
  methods: {

    importFile() {
      this.progress = 0;
      this.importing = true;
      this.$axios.post(this.$global.apiEndpoint(`/dashboard/${this.seriesCode}/${this.bookingOptionId}/highstake-candidate-upload`), this.buildFileForm(), this.buildFileConfig())
          .then(response => {
            this.file = null;
            this.$refs.upload.value = null;
            this.importing = false;
            this.$global.success(response.data.message);
          })
          .catch(err => {
            this.$global.globalAXIOSErrorHandler(err);
            this.file = null;
            this.$refs.upload.value = null;
            this.importing = false;
          })
    },
    buildFileForm() {
      let data = new FormData();
      data.append("file", this.file);
      return data;
    },
    buildFileConfig() {
      let _this = this;
      return {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        onUploadProgress: _this.onUploadProgress
      };
    },
    onUploadProgress(progressEvent) {
      this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
    },
    fileChange(e) {
      this.file = e.target.files[0];
    },
    returnToListings() {
      this.$emit('close');
    },
    downloadTemplate() {
      this.$emit('downloadTemplate');
    },
  },
}
</script>
