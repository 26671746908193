<template>
  <layout>
    <template v-slot:contents>
      <div class="grid grid-cols-5_3 gap-5 w-full justify-between w-full">
        <div class="pb-5 flex flex-col space-y-3">
          <div>
            <label for="subject" class="block text-sm leading-5 font-medium text-gray-700">{{
                translation.labels.subject
              }}</label>
            <select @change="getAssessments" v-model="filter.subject" :disabled="processing || loading"
                    aria-label="Subjects"
                    class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
              <option value="">{{ translation.labels.select_subject_option }}</option>
              <option v-for="_subject in subjects" :value="_subject.id" :key="_subject.id">{{
                  _subject.name
                }}
              </option>
            </select>
          </div>
          <div>
            <label for="assessment"
                   class="block text-sm leading-5 font-medium text-gray-700">{{ translation.labels.assessment }}</label>
            <select @change="getGroups" v-model="filter.assessment" :disabled="processing || loading"
                    aria-label="Assessments"
                    class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
              <option value="">{{ translation.labels.select_assessment_option }}</option>
              <option v-for="_assessment in assessments" :value="_assessment.id" :key="_assessment.title">{{
                  _assessment.title
                }}
              </option>
            </select>
            <div v-if="filter.assessment">
              <button
                  @click="downloadGroups"
                  class="my-2 rounded-md du-ml-px focus:outline-none focus:ring-0 px-4 py-2 border border-blue-500 text-sm leading-4 font-medium text-white bg-blue-500 hover:text-gray-50 hover:bg-blue-400 transition ease-in-out duration-150">
                {{ translation.buttons_links.print_groups }}
              </button>
            </div>
          </div>
          <div>
            <label for="group" class="block text-sm leading-5 font-medium text-gray-700">{{
                translation.labels.schedule_group
              }}</label>
            <select @change="getCandidates" v-model="filter.group" :disabled="processing || loading"
                    aria-label="Schedule groups"
                    class="mt-1 rounded-md block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
              <option value="">{{ translation.labels.select_group_option }}</option>
              <option v-for="_group in groups" :value="_group.id" :key="_group.name">{{
                  _group.name
                }}
              </option>
            </select>
          </div>
          <div v-if="loading"
               class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4">
            <loader class="w-5 text-blue-500"></loader>
            <span class="text-blue-500 ml-2 text-xs">{{ translation.messages.loading_data }}</span>
          </div>
          <div v-if="candidates.length > 0"
               class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 relative">
            <div class="mb-3 sm:p-2 sm:rounded-md sm:bg-gray-100">
              <label class="sr-only">{{ translation.labels.search }}</label>
              <div class="flex rounded-md shadow-sm">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-gray-400">
                      <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input v-model="filter.search" @keyup="searchKeyUpHandler" :disabled="processing || loading"
                         type="text"
                         class="rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-200 text-gray-900"
                         placeholder="Search candidates">
                </div>
              </div>
            </div>
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.username }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.full_name }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(candidate, index) in candidates" :key="index"
                    :class="{'bg-white': index % 2 === 0, 'bg-gray-50': index % 2 !== 0 }">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ candidate.username }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ candidate.forename }} {{ candidate.surname }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">
                      <button :disabled="processing || loading" @click.prevent="changeSelectedCandidates(candidate)"
                              :class="isSelected(candidate) ? 'rounded-l-md border-gray-400 bg-gray-400 hover:bg-gray-300' : 'rounded-r-md border-blue-500 bg-blue-500 hover:bg-blue-400 hover:text-gray-50'"
                              class="du-ml-px focus:outline-none focus:ring-0 relative inline-flex items-center px-4 py-1.5 border text-sm leading-4 font-medium transition ease-in-out duration-150 text-white">
                        <svg v-if="isSelected(candidate)" class="w-5 h-5" fill="currentColor"
                             viewBox="0 0 512 243.58">
                          <path
                              d="M138.43 243.58 0 122.84 140.47 0l20.92 23.91-94.92 83 445.53-.42v31.75l-445.54.41 92.89 81.02z"></path>
                        </svg>
                        <svg v-else class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div v-if="!loading" class="mt-8">
                <pagination
                    @paginate="paginate"
                    :collection-name="translation.labels.candidates"
                    :prev-page="prevPage"
                    :next-page="nextPage"
                    :page-links="pageLinks"
                    :to="pagination.showing.to"
                    :from="pagination.showing.from"
                    :of="pagination.showing.of"
                    :current="pagination.current_page"
                >
                </pagination>
              </div>
            </div>
          </div>
          <div v-else-if="filter.group && !loading" class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"/>
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between items-center">
                <p class="text-sm leading-5 text-blue-700">
                  {{ translation.messages.no_candidates }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="sticky top-4 px-2 py-4 bg-gray-100 rounded flex flex-col space-y-3">
            <h4 class="text-lg py-2 leading-6 font-medium text-gray-900 text-center">
              {{ translation.headings.selected_candidates }}</h4>
            <div v-if="candidatesSelected.length === 0">
              <p>{{ translation.messages.no_candidates_selected }}</p>
            </div>
            <div v-else>
              <ul>
                <li v-for="candidate in candidatesSelected" :key="candidate.id"
                    class="items-center flex flex-row space-x-2">
                  <svg @click="() => changeSelectedCandidates(candidate)" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                       class="w-5 h-5 text-red-400 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                  <p>[{{ candidate.external_id }}] {{ candidate.full_name }}</p>
                </li>
              </ul>
            </div>
            <div class="text-center pt-3">
              <button
                  v-if="candidatesSelected.length > 0"
                  @click="popups.move_candidates.open=true"
                  class="my-2 rounded-md du-ml-px focus:outline-none focus:ring-0 px-4 py-2 border border-blue-500 text-sm leading-4 font-medium text-white bg-blue-500 hover:text-gray-50 hover:bg-blue-400 transition ease-in-out duration-150">
                {{ translation.buttons_links.move_candidates }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
  <move-candidates
      @close="popups.move_candidates = {open: false}"
      @updated="candidateMoveComplete"
      :open="popups.move_candidates.open"
      :groups="groups"
      :candidates="candidatesSelected"
      :invigilators="invigilators"
      :current_group="filter.group"
  ></move-candidates>
</template>

<script>
import Layout from "@/components/Layout";
import Pagination from "@/components/Pagination";
import Loader from "@/components/Loader";
import MoveCandidates from "@/components/MoveCandidates";
import fileDownload from "js-file-download";

export default {
  name: 'ScheduleGroups',
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.home(to, from, next);
  },
  components: {
    Layout,
    Pagination,
    Loader,
    MoveCandidates
  },
  data() {
    return {
      checking: false,
      action: "",
      processing: false,
      bookings: [],
      candidates: [],
      candidatesSelected: [],
      invigilators: [],
      loading: false,
      subjects: [],
      assessments: [],
      groups: [],
      searching: null,
      filter: {
        assessment: "",
        search: "",
        group: "",
        subject: ""
      },
      pagination: {
        showing: {
          from: 0,
          to: 0,
          of: 0
        },
        current_page: 1,
        last_page: 1
      },
      popups: {
        move_candidates: {
          open: false
        }
      }
    };
  },
  computed: {
    translation() {
      return this.$store.state.translatables.translation;
    },
    pageLinks() {
      let links = [];
      for (let i = 1; i <= this.pagination.last_page; i++) {
        links.push(i);
      }
      return links;
    },
    prevPage() {
      return this.pagination.current_page - 1;
    },
    nextPage() {
      return (this.pagination.current_page + 1) > parseInt(this.pagination.last_page) ? 0 : this.pagination.current_page + 1;
    }
  },
  mounted() {
    this.getSubjects();
    this.getInvigilators();
  },
  methods: {
    getSubjects() {
      this.loading = true;
      this.$axios.get(this.$global.apiEndpoint('/schedule-groups/subjects'),
          {
            params: {
              order_by: 'subjectNameAsc'
            }
          })
          .then(response => {
            this.subjects = response.data.subjects;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getInvigilators() {
      this.loading = true;
      this.$axios.get(this.$global.apiEndpoint('/invigilators'), {params: {'paginate': 0}})
          .then(response => {
            this.invigilators = response.data.invigilators;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getGroups() {
      this.changeCandidates();
      this.filter.group = '';
      this.candidatesSelected = [];
      this.$axios.get(this.$global.apiEndpoint('/schedule-groups'),
          {
            params: {
              assessmentId: this.filter.assessment
            }
          })
          .then(response => {
            this.groups = response.data.groups;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getAssessments() {
      if (this.filter.subject === '') {
        return;
      }
      this.changeCandidates();
      this.filter.assessment = '';
      this.filter.group = '';
      this.candidatesSelected = [];
      this.$axios.get(this.$global.apiEndpoint('/schedule-groups/assessments'),
          {
            params: {
              subjectId: this.filter.subject,
              order_by: 'assessmentTitleAsc'
            }
          })
          .then(response => {
            this.assessments = response.data.assessments;
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getCandidates() {
      if (this.filter.assessment === '') {
        return;
      }
      this.changeCandidates();
      this.$axios.get(this.$global.apiEndpoint('/schedule-groups/candidates/group/' + this.filter.group),
          {
            params: {
              query: this.filter.search,
              page: this.pagination.current_page
            }
          })
          .then(response => {
            this.candidates = response.data.candidates.data;
            this.pagination = this.$global.paginationData(response, 'candidates');
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    downloadGroups() {
      this.loading = true;

      this.$axios.post(this.$global.apiEndpoint('/schedule-groups/download'),
          {
            assessment: this.filter.assessment
          })
          .then(response => {
            let title = this.assessments.find(assessment => assessment.id === this.filter.assessment).title;
            fileDownload(response.data, title + ' schedule groups.csv');
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    changeCandidates() {
      this.loading = true;
      this.candidates = [];
    },
    searchKeyUpHandler() {
      if (this.searching !== null) {
        clearTimeout(this.searching);
      }
      this.searching = setTimeout(() => {
        this.paginate(1);
      }, 700);
    },
    paginate(page_number) {
      this.candidates = [];
      this.pagination.current_page = page_number || 1;
      this.getCandidates();
    },
    isSelected(candidate) {
      let selected = this.candidatesSelected.find(x => x.sessions[0].id === candidate.sessions[0].id);
      return selected ? true : false;
    },
    changeSelectedCandidates(candidate) {
      if (this.isSelected(candidate)) {
        let selectedIndex = this.candidatesSelected.findIndex(x => x.sessions[0].id === candidate.sessions[0].id);
        this.candidatesSelected.splice(selectedIndex, 1);
        return;
      }
      this.candidatesSelected.push(candidate);
    },
    candidateMoveComplete(groupId) {

      this.getGroups();

      this.filter.group = groupId;
      this.getCandidates()
    }
  }
}
</script>
