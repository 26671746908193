export default {

    data: {
        app: null
    },

    /**
     * App
     * @param app
     */
    app(app) {
        return this.data.app = app;
    },

    /**
     * Load the languages
     * @param locale
     * @param callback
     */
    translatables(locale, callback) {
        locale = locale || '';
        callback = callback || function(){};
        this.data.app.$axios.get(this.data.app.$global.endpoint(`/api/${this.data.app.$APIVersion}/translatables/${locale}`))
            .then(response => {
                this.data.app.$store.commit('setTranslatables', response.data);
                callback();
            })
            .catch(() => {
                callback();
            });
    },

    /**
     * Trans
     * @param message
     * @param replace
     * @returns {*}
     */
    trans(message, replace) {
        replace = replace || {};
        for(let i in replace) {
            if(Object.prototype.hasOwnProperty.call(replace, i)) {
                message = message.replace(i, replace[i]);
            }
        }
        return message;
    },

    /**
     *
     * @param classes
     * @returns {*}
     */
    rtl(classes) {
        return this.data.app.$store.state.translatables.direction === 'rtl' ? classes : '';
    },

    /**
     *
     * @param classes
     * @returns {*}
     */
    ltr(classes) {
        return this.data.app.$store.state.translatables.direction === 'ltr' ? classes : '';
    },

    /**
     * Set the page load to display.
     */
    pageIsLoading() {
        this.data.app.$store.commit('setPageLoading', true);
    },

    /**
     * Set the page loader to hide.
     */
    pageIsNotLoading() {
        this.data.app.$store.commit('setPageLoading', false);
    },

    /**
     * Get a fresh CSRF token
     * @param callback
     */
    setXSRFToken(callback) {
        this.data.app.$axios.get(this.data.app.$global.endpoint('/sanctum/csrf-cookie'))
            .then(callback);
    },

    /**
     * Endpoint
     * @param endpoint
     * @returns {string}
     */
    endpoint(endpoint) {
        return process.env.APP_API_URL + endpoint;
    },

    /**
     * API Endpoint
     * @param endpoint
     * @returns {string}
     */
    apiEndpoint(endpoint) {
        return this.endpoint(`/api/${this.data.app.$APIVersion}${endpoint}`);
    },

    /**
     * Error
     * @param message
     * @param heading
     */
    error(message, heading) {
        heading = heading || 'Error';
        this.data.app.$nextTick(() => {
            this.data.app.$store.commit('addNotification', {
                type: 'error',
                heading,
                message
            });
        });
    },

    /**
     * Info
     * @param message
     * @param heading
     */
    info(message, heading) {
        heading = heading || 'Information';
        this.data.app.$nextTick(() => {
            this.data.app.$store.commit('addNotification', {
                type: 'info',
                heading,
                message
            });
        });
    },

    /**
     * Success
     * @param message
     * @param heading
     */
    success(message, heading) {
        heading = heading || 'Success';
        this.data.app.$nextTick(() => {
            this.data.app.$store.commit('addNotification', {
                type: 'success',
                heading,
                message
            });
        });
    },

    /**
     * Get Params From Query String
     * @returns {{}}
     */
    getParamsFromQueryString() {
        let params = {};
        if(window.location.search) {
            window.location.search.split('?')[1].split('&').map(p => p.split('=')).forEach(arr => params[arr[0]] = arr[1]);
        }
        return params;
    },

    /**
     * Pagination Data
     * @param response
     * @param key
     * @returns {{last_page: (number|*), showing: {of: number | {jsMemoryEstimate: number, jsMemoryRange: [number, number]} | PaymentItem, from, to}, current_page: (number|*)}}
     */
    paginationData(response, key) {
        return {
            current_page: response.data[key].current_page,
            last_page: response.data[key].last_page,
            showing: {
                from: response.data[key].from,
                to: response.data[key].to,
                of: response.data[key].total
            }
        };
    },

    /**
     * Close All Menus
     */
    closeAllMenus() {
        let e = document.createEvent('HTMLEvents');
        e.initEvent('close-all-menus', false, true);
        document.dispatchEvent(e);
    },

    /**
     * Global AXIOS Error Handler
     * @param error
     * @param showErrors
     * @returns {number}
     */
    globalAXIOSErrorHandler(error, showErrors)
    {
        showErrors = typeof showErrors === 'undefined' ? true : showErrors;

        if(! this.data.app) {
            return setTimeout(() => {
                this.globalAXIOSErrorHandler(error);
            }, 100);
        }

        switch(error.response.status) {
            case 401:
                if(error.response.data.error && showErrors) {
                    this.error(error.response.data.error);
                }
                this.data.app.$store.commit('clear');
                this.data.app.$router.push('/login');
            break;
            case 419:

            break;
            case 422:
                // Validation
                for(let i in error.response.data.errors) {
                    if(Object.prototype.hasOwnProperty.call(error.response.data.errors, i) && showErrors) {
                        this.error(error.response.data.errors[i][0]);
                    }
                }
            break;
            case 498:
                // for(let i in error.response.data.errors) {
                //     if(error.response.data.errors.hasOwnProperty(i)) {
                //         window.error(error.response.data.errors[i][0]);
                //     }
                // }
            break;
            case 500:
                if(showErrors) {
                    this.error(error.response.data);
                }
            break;
        }
    }

}
