
import Global from "@/utils/Global";
import Gates from "@/utils/Gates";
import moment from "moment";

export default {
    install: (app) => {
        app.config.devtools = process.env.APP_API_URL === 'production';
        app.config.globalProperties.$APIVersion = process.env.APP_API_VERSION;
        app.config.globalProperties.$global = Global;
        app.config.globalProperties.$moment = moment;

        window.bus = {
            '$gates': app.config.globalProperties.$gates = Gates
        }
    }
}
