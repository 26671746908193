<template>
  <div>
    <layout>
      <template v-slot:contents>
        <div class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
              {{ translation().headings.reset_your_password }}
            </h1>
          </div>
          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="lg:bg-white py-8 px-4 lg:shadow sm:rounded-lg sm:px-10">
              <div v-if="message" class="rounded-md bg-green-50 p-4 mb-6">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm leading-5 font-medium text-green-800">
                      {{ translation().headings.success }}
                    </h3>
                    <div class="mt-2 text-sm leading-5 text-green-700">
                      <p>{{ message }}</p>
                    </div>
                    <div class="mt-4">
                      <div class="-mx-2 -my-1.5 flex">
                        <button @click.prevent="login" class="px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
                          &larr; {{ translation().buttons_links.login_now }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="errors.global" class="text-red-500 text-sm mb-6">{{ errors.global }}</div>
              <form ref="form">
                <div>
                  <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                    {{ translation().labels.email }}
                  </label>
                  <div v-if="errors.email" class="text-red-500 text-sm">{{ errors.email }}</div>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input autocomplete="email" :disabled="processing" v-model="email" id="email" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="mt-6">
                  <label for="school_id" class="block text-sm font-medium leading-5 text-gray-700">
                    {{ translation().labels.school_id }}
                  </label>
                  <div v-if="errors.school_id" class="text-red-500 text-sm">{{ errors.school_id }}</div>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input autocomplete="organization" :disabled="processing" v-model="school_id" id="school_id" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="mt-6">
                  <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                    {{ translation().labels.password }}
                  </label>
                  <div v-if="errors.password" class="text-red-500 text-sm">{{ errors.password }}</div>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input autocomplete="new-password" :disabled="processing" v-model="password" id="password" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="mt-6">
                  <label for="password_confirmation" class="block text-sm font-medium leading-5 text-gray-700">
                    {{ translation().labels.confirm_password }}
                  </label>
                  <div v-if="errors.password_confirmation" class="text-red-500 text-sm">{{ errors.password_confirmation }}</div>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input autocomplete="new-password" :disabled="processing" v-model="password_confirmation" id="password_confirmation" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="mt-6">
                  <span class="block w-full rounded-md shadow-sm">
                    <button :disabled="processing" @click.prevent="reset" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        <loader v-if="processing" class="w-5 h-5"></loader>
                        <span v-else>{{ translation().buttons_links.reset_password }}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

export default {
  beforeRouteEnter (to, from, next) {
    window.bus.$gates.resetPassword(to, from, next);
  },
  mounted() {
    this.token = this.$route.params.token;
    this.school_id = this.$route.query.school_id;
    this.email = this.$route.query.email;
  },
  components: {
    Layout,
    Loader
  },
  data() {
    return {
      processing: false,
      email: '',
      school_id: '',
      password: '',
      password_confirmation: '',
      message: '',
      token: '',
      errors: {
        global: '',
        email: '',
        school_id: '',
        password: '',
        token: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    setXSRFToken(callback) {
      this.$axios.get(this.$global.endpoint('/sanctum/csrf-cookie'))
          .then(callback);
    },
    reset() {
      this.clearErrors();

      if (! this.$refs.form.checkValidity()) {
        return this.$refs.form.reportValidity();
      }

      this.processing = true;

      this.setXSRFToken(() => {
        this.$axios.post(this.$global.endpoint('/sanctum/users/password/reset'), this.payload())
            .then((response) => {
              this.clearForm();
              this.message = response.data.message;
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error, this);
            })
            .finally(() => {
              this.processing = false;
            });
      });
    },
    payload() {
      return {
        locale: this.$store.state.translatables.locale,
        email: this.email,
        school_id: this.school_id,
        password: this.password,
        token: this.token,
        password_confirmation: this.password_confirmation,
        device_name: window.WURFL ? window.WURFL.complete_device_name : 'undefined'
      }
    },
    clearForm() {
      this.email = '';
      this.school_id = '';
      this.password = '';
      this.password_confirmation = '';
      this.token = '';
    },
    clearErrors() {
      this.errors = {
        global: '',
        email: '',
        school_id: '',
        password: '',
        password_confirmation: '',
        token: '',
      };
    },
    login() {
      this.$router.push({ name: 'Login' });
    },
  }
}
</script>
