<template>
    <div>
        <transition
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from-class="opacity-0"
            enter-to-class="opacity-75"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from-class="opacity-75"
            leave-to-class="opacity-0"
            v-on:after-enter="afterEnter"
        >
            <div v-show="open" @click.prevent="toggle" class="fixed inset-0 bg-gray-800 opacity-75 z-50"></div>
        </transition>
        <section class="fixed inset-y-0 max-w-full right-0 flex z-50">
            <transition
                enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from-class="translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from-class="translate-x-0"
                leave-to-class="translate-x-full"
            >
                <div v-show="open" class="w-screen max-w-2xl bg-gray-50">
                    <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                        <div class="flex-1 h-0 overflow-y-auto">
                            <header class="space-y-1 py-4 px-4 bg-gray-800 sm:px-6">
                                <div class="flex items-center justify-between space-x-3">
                                    <h2 class="text-lg leading-7 font-medium text-white">
                                        {{ title }}
                                    </h2>
                                    <div class="h-7 flex items-center">
                                        <button @click.prevent="toggle" aria-label="Close panel" class="text-indigo-200 hover:text-white transition ease-in-out duration-150">
                                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <p class="text-sm leading-5 text-indigo-300">
                                        {{ subTitle }}
                                    </p>
                                </div>
                            </header>
                            <div class="flex-1 flex flex-col justify-between">
                                <div class="p-4 sm:p-6">
                                    <slot name="contents"></slot>
                                </div>
                            </div>
                        </div>
                        <div v-if="cancelText || saveText" class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                            <span v-if="cancelText" class="inline-flex rounded-md shadow-sm">
                                <button @click.prevent="toggle" type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                    {{ cancelText }}
                                </button>
                            </span>
                            <span v-if="saveText" @click.prevent="save" class="inline-flex rounded-md shadow-sm ml-2">
                                <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    {{ saveText }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
        </section>
    </div>
</template>

<script>
    export default {
        emits: ['changed', 'save'],
        props: {
            open: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            subTitle: {
                type: String,
                default: ''
            },
            cancelText : {
                type: String,
                default: ''
            },
            saveText : {
                type: String,
                default: ''
            }
        },
        methods: {
            toggle() {
                this.$emit('changed', ! this.open);
            },
            afterEnter(el) {
                el.classList.add('opacity-75');
            },
            save() {
                this.$emit('save');
            }
        }
    }
</script>
