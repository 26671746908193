<template>
  <popup
      :open="open"
      :title="translation.headings.move_candidate_group"
      @cancel="close"
      @state-change="stateChange"
      :maxWidth="'sm:max-w-3xl'"
  >
    <template v-slot:icon>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </template>
    <template v-slot:content>
      <div class="flex">
          <div class="w-1/2 mx-2 relative focus-within:z-10">
            <h4 class="py-2 text-md leading-6 font-medium text-gray-900">{{ translation.headings.existing_candidate_groups }}</h4>
            <div v-if="availableGroups().length === 0">{{ translation.messages.no_schedule_groups }}</div>
            <div v-else>
              <select @change="getAssessments" v-model="existing_group" :disabled="processing || loading"
                      aria-label="Schedule groups"
                      class="max-w-200 bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 border-gray-200 border-r-1 text-gray-900 sm:text-sm rounded-none rounded-l-md">
                <option value="">{{ translation.labels.select_group_option }}</option>
                <option v-for="_group in availableGroups()" :value="_group.id" :key="_group.id">{{
                    _group.name
                  }}
                </option>
              </select>
            </div>
        </div>
        <div class="w-1/2 mx-2">
          <h4 class="py-2 text-md leading-6 font-medium text-gray-900">{{ translation.headings.new_group }}</h4>
          <input v-model="new_group" :placeholder="translation.labels.placeholder_group_name" id="full_name" :disabled="processing" type="text" class="block w-full sm:text-sm sm:leading-5 rounded-md border-gray-300">
          <div class="my-2 p-2 border border-gray-300 rounded-md">
            <h5 class="text-md leading-6 font-medium text-gray-900">{{ translation.headings.invigilators }}</h5>
            <div class="overflow-scroll h-32">
              <div class="text-left " v-for="invigilator in invigilators" :key="invigilator.id">
                <label :title="invigilator.full_name" class="px"
                       :for="invigilator.id">
                  <input class="mx-2 my-1" type="checkbox"
                         :id="invigilator.id"
                         :value="invigilator.id"
                         v-model="invigilatorsSelected"
                  >
                  <span>{{ truncate(invigilator.full_name, 35) }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
              :disabled="preventSubmit()"
              :class="formInvalid() ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-500'"
              @click="moveCandidates"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>{{ translation.buttons_links.move_candidates }}</span>
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button :disabled="processing" @click.prevent="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            {{ translation.buttons_links.close }}
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
  import Loader from "@/components/Loader";
  import Popup from "@/components/Popup";

  export default {
    emits: ['close', 'updated'],
    components: {
      Loader,
      Popup
    },
    props: {
      open: {
        type: Boolean,
        default: false
      },
      candidates: {
        type: Array
      },
      groups: {
        type: Array
      },
      invigilators: {
        type: Array
      },
      current_group: null
    },
    data() {
      return {
        loading: false,
        processing: false,
        existing_group: '',
        new_group: '',
        invigilatorsSelected: []
      };
    },
    computed: {
      translation() {
        return this.$store.state.translatables.translation;
      }
    },
    methods: {
      stateChange() {
        this.new_group = "";
        this.existing_group = "";
        this.invigilatorsSelected = [];
      },
      moveCandidates() {
        this.processing = true;
        this.$axios.post(this.$global.apiEndpoint(`/schedule-groups/move-candidates`), {
          current_group: this.current_group,
          existing_group: this.existing_group,
          new_group: this.new_group,
          candidate_sessions: this.candidates.map(function(candidate){
            return candidate.sessions[0].id;
          }),
          invigilators: this.invigilatorsSelected
        })
            .then((response) => {
              this.$emit('updated', response.data.groupId);
              this.close();
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
            })
            .finally(() => {
              this.processing = false;
            });
      },
      preventSubmit(){
        return this.processing || this.formInvalid()
      },
      formInvalid() {
        if(this.existing_group === '' && this.new_group === ''){
          return true;
        }
        if(this.existing_group !== '' && this.new_group !== ''){
          return true;
        }
        return false;
      },
      availableGroups() {
        let currentGroup = this.current_group;
        return this.groups.filter(function(group){
          return group.id !== currentGroup;
        });
      },
      close() {
        this.$emit('close');
      },
      update(){
        console.log(this.invigilatorsSelected);
      },
      truncate(str, max) {
        if(str.length > max){
          return str.substring(0,max) + '...';
        }
        return str;
      }
    }
  }
</script>
