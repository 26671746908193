<template>
  <popup
      :open="open"
      :title="title"
      @cancel="close"
      @state-change="stateChange"
      v-if="translation"
      :maxWidth="schedule_group_search_open ? 'sm:max-w-3xl' : 'sm:max-w-lg'"
  >
    <template v-slot:icon>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </template>

    <template v-slot:content>
      <div class="flex flex-row my-6">

        <!-- Left side -->
        <div class="flex-1 pr-3">
          <div class="text-left">
            <label for="full_name" class="block text-sm font-medium leading-5 text-gray-700">{{ translation.labels.full_name }}</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <input v-model="values.full_name" id="full_name" :disabled="processing" type="text" class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300">
            </div>
          </div>
          <div class="text-left mt-4">
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700">{{ translation.labels.email }}</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                </svg>
              </div>
              <input v-model="values.email" id="email" :disabled="processing" type="text" class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300">
            </div>
          </div>
          <div class="text-left mt-4">
            <label for="username" class="block text-sm font-medium leading-5 text-gray-700">{{ translation.labels.username }}</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <input v-model="values.username" id="username" :disabled="processing" type="text" class="block w-full pl-10 sm:text-sm sm:leading-5 rounded-md border-gray-300">
            </div>
          </div>
          <div class="text-left mt-4">
            <label class="block text-sm font-medium leading-5 text-gray-700">{{ translation.labels.schedule_groups }}</label>
            <div class="mt-1 relative flex flex-wrap gap-2 align-center text-center">
              <div v-for="group in values.schedule_groups" :key="group.id" class="flex flex-initial space-x-2 bg-indigo-100 border border-indigo-200 rounded-md px-2 py-1">
                <p>{{ group.name }}</p>
                <p @click="removeScheduleGroup(group.id)" class="cursor-pointer">x</p>
              </div>
            </div>
          </div>
          <div class="text-left mt-4">
            <a href="#" @click.prevent="openScheduleGroupSearch">{{ translation.buttons_links.assign_schedule_group }}</a>
          </div>
        </div>

        <!-- Right side -->
        <div v-show="schedule_group_search_open" class="flex-1 border-l-2 pl-3">
          <div class="text-left">
            <label for="series" class="block text-sm font-medium leading-5 text-gray-700">
              {{ translation.labels.series }}
              <loader v-if="series_list.length === 0" class="ml-3 h-5 w-5 inline-flex" />
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <select id="series" v-model="selected_series"  class="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-gray-300">
                <option v-for="series in series_list" :key="series.id" :value="series.id">
                  {{ series.code }}
                </option>
              </select>
            </div>
          </div>

          <div v-show="selected_series" class="text-left mt-4">
            <label for="assessment" class="block text-sm font-medium leading-5 text-gray-700">
              {{ translation.labels.assessment }}
              <loader v-if="assessment_list.length === 0" class="ml-3 h-5 w-5 inline-flex" />
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <select id="assessment" v-model="selected_assessment" class="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-gray-300">
                <option v-for="assessment in assessment_list" :key="assessment.id" :value="assessment.id">
                  {{ assessment.title }}
                </option>
              </select>
            </div>
          </div>

          <div v-show="selected_assessment" class="text-left mt-4">
            <label for="schedule_group" class="block text-sm font-medium leading-5 text-gray-700">
              {{ translation.labels.schedule_group }}
              <loader v-if="schedule_group_list.length === 0" class="ml-3 h-5 w-5 inline-flex" />
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <select id="schedule_group" v-model="selected_schedule_group" class="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-gray-300">
                <option v-for="group in schedule_group_list" :key="group.id" :value="group.id">
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </template>

    <template v-slot:footer>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button :disabled="processing" @click.prevent="submit" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <loader v-if="processing" class="w-5 h-5"></loader>
            <span v-else>
              <span v-if="invigilator">{{ translation.buttons_links.save_invigilator }}</span>
              <span v-else>{{ translation.buttons_links.create_invigilator }}</span>
            </span>
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button :disabled="processing" @click.prevent="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            {{ translation.buttons_links.close }}
          </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
  import Loader from "@/components/Loader";
  import Popup from "@/components/Popup";

  export default {
    emits: ['close', 'updated', 'created'],
    components: {
      Loader,
      Popup
    },
    props: {
      invigilator: {
        type: Object
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      open() {
        if (this.open) {
          this.getSeriesList();
        }
      },
      selected_series() {
        this.selected_assessment = null;
        this.assessment_list = [];

        if (this.selected_series !== null) {
          this.getAssessmentList(this.selected_series);
        }
      },
      selected_assessment() {
        this.selected_schedule_group = null
        this.schedule_group_list = [];

        if (this.selected_assessment !== null) {
          this.getScheduleGroupList(this.selected_assessment);
        }
      },
      selected_schedule_group() {
        if (this.selected_schedule_group !== null) {
          this.addScheduleGroup(this.selected_schedule_group);
        }
      },
    },
    computed: {
      title() {
        if (this.invigilator === null) {
          return 'Create Invigilator';
        }

        return 'Edit Invigilator';
      },
      translation() {
        return this.$store.state.translatables.translation;
      },
      payload() {
        return {
          ...this.values,
          schedule_groups: this.values.schedule_groups.map(group => group.id),
        }
      },
    },
    data() {
      return {
        processing: false,
        values: {
          username: '',
          full_name: '',
          email: '',
          id: '',
          schedule_groups: [],
        },
        selected_series: null,
        selected_assessment: null,
        selected_schedule_group: null,
        series_list: [],
        assessment_list: [],
        schedule_group_list: [],
        schedule_group_search_open: false,
      };
    },
    methods: {
      stateChange(state) {
        this.$nextTick(() => {
          if(state) {
            this.values = {
              username: this.invigilator && this.invigilator.username,
              full_name: this.invigilator && this.invigilator.full_name,
              email:  this.invigilator && this.invigilator.email,
              id:  this.invigilator && this.invigilator.id,
              schedule_groups: this.invigilator ? this.invigilator.schedule_groups : [],
            };
          }
        });
      },
      close() {
        this.selected_series = null;
        this.selected_assessment = null;
        this.selected_schedule_group = null;
        this.series_list = [];
        this.assessment_list = [];
        this.schedule_group_list = [];
        this.schedule_group_search_open = false;

        this.$emit('close');
      },
      submit() {
        this.invigilator === null ? this.createInvigilator() : this.saveInvigilator();
      },
      createInvigilator() {
        this.processing = true;
        this.$axios.post(this.$global.apiEndpoint('/invigilators'), this.payload)
            .then(response => {
              this.processing = false;
              this.$emit('created', response.data.invigilator);
              this.close();
              this.$global.success(response.data.message);
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
              this.processing = false;
            });
      },
      saveInvigilator() {
        this.processing = true;
        this.$axios.patch(this.$global.apiEndpoint(`/invigilators/${this.invigilator.id}`), this.payload)
          .then(response => {
            this.processing = false;
            this.$emit('updated', this.values);
            this.close();
            this.$global.success(response.data);
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
            this.processing = false;
          });
      },
      openScheduleGroupSearch() {
        this.schedule_group_search_open = true;
      },
      getSeriesList() {
        this.$axios.get(this.$global.apiEndpoint('/bookings/series-list'))
            .then(response => {
              this.series_list = response.data.series;
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
            });
      },
      getAssessmentList(series_id) {
        this.$axios.get(this.$global.apiEndpoint(`/assessments/series/${series_id}`))
            .then(response => {
              this.assessment_list = response.data.assessments;
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
              this.selected_series = null;
            });
      },
      getScheduleGroupList(assessment_id) {
        this.$axios.get(this.$global.apiEndpoint(`/assessments/${assessment_id}/schedule-groups`))
            .then(response => {
              this.schedule_group_list = response.data.groups;
            })
            .catch(error => {
              this.$global.globalAXIOSErrorHandler(error);
              this.selected_assessment = null;
            });
      },
      addScheduleGroup(schedule_group_id) {
        if (! this.isScheduleGroupSelected(schedule_group_id)) {
          this.values.schedule_groups.push(this.schedule_group_list.find(group => group.id === schedule_group_id));
          this.schedule_group_search_open = false;
        }
      },
      isScheduleGroupSelected(schedule_group_id) {
        return this.values.schedule_groups.filter(group => group.id === schedule_group_id).length > 0;
      },
      removeScheduleGroup(schedule_group_id) {
        this.values.schedule_groups = this.values.schedule_groups.filter(group => group.id !== schedule_group_id);
      }
    }
  }
</script>
