import axios from './plugins/axios'
import setup from './plugins/setup'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

const app = createApp(App)
    .use(setup)
    .use(store)
    .use(router)
    .use(axios);

app.mixin({
    methods: {
        trans(message, replace) {
            return this.$global.trans(message, replace);
        },
        translation() {
            return this.$store.state.translatables.translation;
        },
        ltr(string) {
            return this.$global.ltr(string);
        },
        rtl(string) {
            return this.$global.rtl(string);
        },
        setXSRFToken(token) {
            return this.$global.setXSRFToken(token);
        }
    }
})

app.mount('#app');
