<template>
  <layout>
    <template v-slot:contents>
      <assessment-selection
        :show="! assessment"
        @selected="assessmentSelected"
      />

      <coursework-mark-entry
        v-if="assessment && assessment.type === 'coursework'"
        :assessment="assessment"
        :series="series"
        @cancel="entryCancelled"
      />

      <endorsement-grade-entry
        v-if="assessment && assessment.type === 'endorsement'"
        :assessment="assessment"
        :series="series"
        @cancel="entryCancelled"
      />

    </template>
  </layout>
</template>

<script>
import Layout from "../components/Layout";
import AssessmentSelection from "../components/AssessmentSelection";
import CourseworkMarkEntry from "../components/CourseworkMarkEntry";
import EndorsementGradeEntry from "../components/EndorsementGradeEntry";

export default {
  components: {
    CourseworkMarkEntry,
    Layout,
    AssessmentSelection,
    EndorsementGradeEntry,
  },
  data() {
    return {
      loading: false,
      assessment: null,
      series: null,
    };
  },
  methods: {
    assessmentSelected({ assessment, series }) {
      this.assessment = assessment;
      this.series = series;
    },
    entryCancelled() {
      this.assessment = null;
      this.series = null;
    },
  }
}
</script>
