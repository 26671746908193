<template>
    <div>
        <div class="flex items-center justify-between">
            <div v-if="prevPage || nextPage" class="flex-1 flex justify-between sm:hidden">
                <a v-if="prevPage" @click.prevent="paginate(prevPage)" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Previous
                </a>
                <a v-if="nextPage" @click.prevent="paginate(nextPage)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Next
                </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p class="text-sm leading-5 text-gray-700">
                        Showing
                        <span class="font-medium">{{ from }}</span>
                        to
                        <span class="font-medium">{{ to }}</span>
                        of
                        <span class="font-medium">{{ of }}</span>
                        {{ collectionName }}
                    </p>
                </div>
                <div>
                    <span class="relative z-0 inline-flex shadow-sm">
                        <button v-if="prevPage" @click.prevent="paginate(prevPage)" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                        <button v-if="showFirstNumber" @click.prevent="paginate(firstNumber)" type="button" class="bg-white text-gray-700 hover:text-gray-500 -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none active:text-gray-700 transition ease-in-out duration-150">
                            ...{{ firstNumber }}
                        </button>
                        <button v-for="(linkNumber, index) in formattedPageLinks" :key="index" @click.prevent="paginate(linkNumber)" :class="{ 'bg-blue-500 text-white border-blue-700': current === linkNumber, 'bg-white text-gray-700 hover:text-gray-500 border-gray-300': current !== linkNumber }" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium focus:z-10 focus:outline-none active:text-gray-700 transition ease-in-out duration-150">
                            {{ linkNumber }}
                        </button>
                        <button v-if="showLastNumber" @click.prevent="paginate(lastNumber)" type="button" class="bg-white text-gray-700 hover:text-gray-500 -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none active:text-gray-700 transition ease-in-out duration-150">
                            ...{{ lastNumber }}
                        </button>
                        <button v-if="nextPage" @click.prevent="paginate(nextPage)" type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      emits: ['paginate'],
        props: {
            collectionName: {
                type: String,
                default: ''
            },
            position: {
                type: String,
                default: 'bottom'
            },
            pageLinks: {
                type: Array,
                default:  () => {
                  return []
                }
            },
            current: {
                type: Number,
                default: 0,
            },
            from: {
                type: Number,
                default: 0,
            },
            to: {
                type: Number,
                default: 0,
            },
            of: {
                type: Number,
                default: 0,
            },
            nextPage: {
                type: Number,
                default: 0,
            },
            prevPage: {
                type: Number,
                default: 0,
            }
        },
        computed: {
            firstNumber() {
                return this.pageLinks[0];
            },
            showFirstNumber() {
                return this.formattedPageLinks.indexOf(this.firstNumber) === -1;
            },
            lastNumber() {
                return this.pageLinks[this.pageLinks.length -1];
            },
            showLastNumber() {
                return this.formattedPageLinks.indexOf(this.lastNumber) === -1;
            },
          formattedPageLinks() {
                const maxLinks = 9;
                if(this.pageLinks.length <= maxLinks) {
                    return this.pageLinks;
                }
                let from = this.current - ((maxLinks-1)/2);
                let linksClone = [...this.pageLinks];

                if(from <= (maxLinks-1)/2) {
                    return linksClone.splice(0, maxLinks);
                }

                linksClone = [...this.pageLinks];
                let toReturn = linksClone.splice(this.pageLinks.indexOf(this.pageLinks.filter(page => page === from)[0]), maxLinks);

                if(toReturn.length < maxLinks) {
                    linksClone = [...this.pageLinks];
                    from = this.pageLinks[this.pageLinks.length -1] - (maxLinks-1);
                    toReturn = linksClone.splice(this.pageLinks.indexOf(from), maxLinks);
                }

                return toReturn;
            }
        },
        methods: {
            paginate(page) {
                this.$emit('paginate', page);
            }
        }
    }
</script>
