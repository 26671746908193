<template>
  <layout>
    <template v-slot:contents>
      <div v-if="translation" id="invigilators" v-cloak class="flex flex-col">
        <div class="sm:flex items-center sm:p-2 sm:rounded-md sm:bg-gray-100 mb-5">
          <span class="flex w-full sm:w-auto sm:inline-flex rounded-md shadow-sm mb-4 sm:mb-0 ml-auto">
            <button :disabled="loading" @click.prevent="createInvigilatorClickHandler" type="button" class="flex w-full sm:w-auto sm:inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
              <svg class="h-5 w-5 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{{ translation.buttons_links.create_invigilator }}</span>
            </button>
          </span>
        </div>

        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 relative">
          <div :class="{'pb-40': invigilators.length}" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.invigilator }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.email }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.username }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{ translation.labels.registration_complete }}
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-if="! invigilators.length && ! loading">
                  <td colspan="20" class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="rounded-md bg-blue-50 p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between items-center">
                          <p class="text-sm leading-5 text-blue-700">
                            {{ translation.messages.no_invigilators }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-for="(invigilator, index) in invigilators" :key="index" :class="{'bg-white': index % 2 === 0, 'bg-gray-50': index % 2 !== 0 }">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 font-medium text-gray-900">{{ invigilator.full_name }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">{{ invigilator.email }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">{{ invigilator.username }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-900">
                      <svg v-if="invigilator.completed_registration" class="mr-3 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <svg v-else class="mr-3 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    <div class="invigilator-list-menu">
                      <list-menu>
                        <template v-slot:content>
                          <a @click.prevent="editInvigilatorClickHandler(invigilator)" href="#" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                            <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                            {{ translation.buttons_links.edit_invigilator }}
                          </a>
                          <a v-if="! invigilator.completed_registration && invigilator.email" @click.prevent="resendRegistrationClickHandler(invigilator)" href="#" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                            <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                            <loader v-if="sending_email" class="w-5 text-blue-500"></loader>
                            <span v-else>{{ translation.buttons_links.resend_email }}</span>
                          </a>
                        </template>
                      </list-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!loading" class="mt-8">
            <pagination
                @paginate="paginate"
                :collection-name="'Invigilators'"
                :prev-page="prevPage"
                :next-page="nextPage"
                :page-links="pageLinks"
                :to="pagination.showing.to"
                :from="pagination.showing.from"
                :of="pagination.showing.of"
                :current="pagination.current_page"
            >
            </pagination>
          </div>
        </div>
        <div v-if="loading" class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4">
          <loader class="w-5 text-blue-500"></loader> <span class="text-blue-500 ml-2 text-xs">{{ translation.messages.loading_data }}</span>
        </div>
      </div>
    </template>
  </layout>
  <create-edit-invigilator
      @close="popups.create_edit_invigilator = {open: false, invigilator: null}"
      @updated="invigilatorUpdated"
      @created="invigilatorCreated"
      :open="popups.create_edit_invigilator.open"
      :invigilator="popups.create_edit_invigilator.invigilator"
  ></create-edit-invigilator>
</template>

<script>
import Layout from "@/components/Layout";
import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";
import ListMenu from "../components/ListMenu";
import CreateEditInvigilator from "../components/CreateEditInvigilator";

export default {
  name: 'Invigilators',
  beforeRouteEnter(to, from, next) {
    window.bus.$gates.home(to, from, next);
  },
  components: {
    Layout,
    Loader,
    ListMenu,
    Pagination,
    CreateEditInvigilator,
  },
  computed: {
    pageLinks() {
      let links = [];
      for(let i = 1; i <= this.pagination.last_page; i++) {
        links.push(i);
      }
      return links;
    },
    prevPage() {
      return this.pagination.current_page - 1;
    },
    nextPage() {
      return (this.pagination.current_page + 1) > parseInt(this.pagination.last_page) ? 0 : this.pagination.current_page + 1;
    },
    translation() {
      return this.$store.state.translatables.translation;
    },
  },
  data() {
    return {
      loading: false,
      invigilators: [],
      sending_email: false,
      pagination: {
        showing: {
          from: 0,
          to: 0,
          of: 0
        },
        current_page: 1,
        last_page: 1
      },
      popups: {
        create_edit_invigilator: {
          open: false,
          invigilator: null
        },
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.loading = true;
      this.$axios.get(this.$global.apiEndpoint(`/invigilators?page=${this.pagination.current_page}`))
          .then(response => {
            this.invigilators = response.data.invigilators.data;
            this.pagination = this.$global.paginationData(response, 'invigilators');
            this.republishQueryParameters();
            this.loading = false;
            window.scrollTo(0,0);
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          });
    },
    paginate(page_number) {
      this.invigilators = [];
      this.pagination.current_page = page_number;
      this.get();
    },
    republishQueryParameters() {
      if (history.pushState) {
        let newURL = window.location.protocol + "//" + window.location.host + window.location.pathname + `?page=${this.pagination.current_page}`;
        window.history.pushState({path:newURL}, '', newURL);
      }
    },
    createInvigilatorClickHandler() {
      this.$global.closeAllMenus();
      this.popups.create_edit_invigilator = {
        open: true,
        invigilator: null,
      }
    },
    editInvigilatorClickHandler(invigilator) {
      this.$global.closeAllMenus();
      this.popups.create_edit_invigilator = {
        open: true,
        invigilator
      }
    },
    resendRegistrationClickHandler(invigilator) {
      if (this.sending_email) {
        return;
      }

      this.sending_email = true;
      this.$axios.post(this.$global.apiEndpoint(`/invigilators/${invigilator.id}/send-email`))
          .then(response => {
            this.sending_email = false;
            this.$global.closeAllMenus();
            this.$global.success(response.data);
          })
          .catch(error => {
            this.sending_email = false;
            this.$global.globalAXIOSErrorHandler(error);
          });
    },
    invigilatorUpdated(values) {
      this.invigilators.forEach(invigilator => {
        if(invigilator.id === values.id) {
          invigilator.full_name = values.full_name;
          invigilator.username = values.username;
          invigilator.email = values.email;
          invigilator.schedule_groups = values.schedule_groups;
        }
      })
    },
    invigilatorCreated(values) {
      this.invigilators.push({
        id: values.id,
        full_name: values.full_name,
        username: values.username,
        email: values.email,
        schedule_groups: values.schedule_groups,
      });
    }
  }
}
</script>
