<template>
  <popup
      :open="open"
      :max-width="'max-w-3xl'"
      @cancel="closeTermsModal"
  >
    <template v-slot:icon>
      <div class="text-left pb-5 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Pearson Mock Service – Terms and conditions of use
        </h3>
      </div>
    </template>
    <template v-slot:content>
      <div class="prose prose-sm text-left max-w-none mb-10 bt-5 max-h-terms overflow-y-scroll scrollbar -mx-4">
        <div class="px-4">

          <p>At Pearson, we’re continuing to develop new and innovative ways to support schools, colleges and teachers. We know how important it is for your students to be able to sit unseen mock exam papers in preparation for their live exams at GCSE and A level, but we are also aware that mock exams can be a real burden on teachers’ time. That’s why we’re offering a new Mocks Service.</p>

          <h2>1 What is the Mocks Service?</h2>
          <p>The Mocks Service provides schools and colleges with the Pearson Edexcel GCSE and A level exam papers for use in mock examinations. The papers are sat by students and marked by either Pearson examiners or the school teaching staff, depending on the level of service opted for, with the results and item level analysis then made available via ResultsPlus. Our free Access to Scripts service will enable access to students’ mock exam papers to see the answers they’ve written and the marks they’ve scored for each question.</p>

          <p><strong>There will be <u>two</u> levels of service:</strong></p>

          <ul>
            <li>Paper-Based Pearson-Marked</li>
            <li>Onscreen Pearson-Marked</li>
          </ul>

          <p>1.1 These Mocks Service Terms and Conditions set out the terms and conditions for which Pearson (“we”, “our”, “us”) process requests from our students or agents acting on their behalf (“you”, “your”) for the following services (“the Services”):</p>

          <ul>
            <li>Paper-Based Pearson-Marked</li>
            <li>Onscreen Pearson-Marked</li>
          </ul>

          <p>1.2 By submitting an entry for the Mocks Service, you agree to these Terms and Conditions.</p>

          <h2>2 What are the different services?</h2>

          <h2>Paper-Based Pearson-Marked</h2>

          <p><strong>Services provided by Pearson</strong></p>

          <ul>
            <li>Delivery of papers</li>
            <li>Marking of papers</li>
            <li>Student marks uploaded onto Results Plus</li>
            <li>Access to Scripts</li>
            <li>We will aim to have papers marked by the examining team and all marks uploaded to ResultsPlus within a 3-week time period.</li>
          </ul>

          <p><strong>Responsibility of Centres</strong></p>

          <ul>
            <li>Ensure all students entered for the service have a unique candidate ID</li>
            <li>Ensure all student data provided is correct, when making entries and on exam paper front covers</li>
            <li>To read and follow the administration guidance which will be provided with paper scripts</li>
            <li>Return of completed attendance registers</li>
            <li>Return of completed papers to specified address (envelopes will be provided by Pearson)</li>
          </ul>

          <h2>Onscreen Pearson-Marked</h2>

          <p><strong>Services provided by Pearson</strong></p>

          <ul>
            <li>Guidance for students on using the onscreen platform</li>
            <li>Mock examination(s) provided on our onscreen platform</li>
            <li>Technical support with platform – set up and during test period</li>
            <li>Marking of papers</li>
            <li>Student marks uploaded onto ResultsPlus</li>
            <li>Access to scripts</li>
            <li>We will aim to have papers marked by the examining team and all marks uploaded to ResultsPlus within a 2-week time period.</li>
          </ul>

          <p><strong>Responsibility of centres</strong></p>

          <ul>
            <li>Ensure all students entered for the service have a unique candidate ID</li>
            <li>Provide Pearson with contact details for a centre nominated invigilator</li>
            <li>Provide Pearson with in-centre technical support contact details</li>
            <li>Ensure you have the equipment and can meet the technical requirements to deliver onscreen assessments ahead of signing up for the onscreen service. The list of technical requirements can be found on the Onscreen assessment Mocks Service webpage.</li>
            <li>Ensure students will be confident sitting assessments onscreen ahead of signing up for the onscreen service.</li>
            <li>Ensure students with access requirements will be adequately supported through the accessibility functionality of our onscreen Mock Services (see the Mocks Service onscreen assessment webpage) *</li>
            <li>Ensure any extra time required for the onscreen services is requested as part of the entries process. This can be done for individual students once they have been entered for their exam.</li>
          </ul>

          <p>* If a student taking an onscreen exam requires a reader or a scribe it will be the centre’s responsibility to make these arrangements as normal.</p>

          <p>The accessibility functionality available for our onscreen Mocks Service can be seen on the Onscreen assessment Mocks Service web page.</p>

          <h2>3. Charges for the Services</h2>

          <p>3.1 We will charge you for the provision of Services in accordance with the Charges published on our website and displayed to you on the order page. The cost of delivering the scripts to centres is included, however it is the centre’s responsibility to pay for return of scripts.</p>
          <p>3.2 The following prices are correct as of September 2020 and are subject to change.</p>


          <div class="grid grid-cols-12 gap-1 my-10">
            <div class="col-span-2 font-bold px-4 py-2 bg-blue-300">Qual</div>
            <div class="col-span-5 font-bold px-4 py-2 bg-blue-300">Service</div>
            <div class="col-span-5 font-bold px-4 py-2 bg-blue-300">Price</div>
            <div class="col-span-2 flex items-center justify-center bg-blue-300 font-bold">GCSE</div>
            <div class="col-span-10">
              <div class="grid grid-cols-2 gap-0">
                <div class="col-span-1 bg-blue-100 py-2 px-4">Paper-Based Pearson - Marked</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">&pound;9 per paper per student</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">Onscreen Pearson - Marked</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">&pound;5.50 per paper per student</div>
              </div>
            </div>
            <div class="col-span-2 flex items-center justify-center bg-blue-300 font-bold">A Level</div>
            <div class="col-span-10">
              <div class="grid grid-cols-2 gap-0">
                <div class="col-span-1 bg-blue-100 py-2 px-4">Paper-Based Pearson - Marked</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">&pound;14 per paper per student</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">Onscreen Pearson - Marked</div>
                <div class="col-span-1 bg-blue-100 py-2 px-4">&pound;10.50 per paper per student</div>
              </div>
            </div>
          </div>

          <p>*Due to VAT legislation, students must be under 19 at the time they start the exams.</p>

          <h2>4. Cancellation of services and refund of charges</h2>

          <p>Each Mocks Service series will have an entry deadline date. Any entries made after this date will need to be made for a subsequent mock service series. You can cancel your entries for a mocks service series up to the entry deadline date for the series otherwise you will be charged full price for all entries. Entry deadline dates for each mocks series booking window can be found on the <a href="https://qualifications.pearson.com/en/support/Services/pearson-edexcel-mocks-service/mocks-service-booking-window.html">Mocks Service</a> booking windows web page.</p>

          <h2>5. Returning your paper scripts</h2>

          <p>If you are using our paper-based service, please return your scripts by courier to the following address as soon as possible after each question paper has been sat by students:</p>

          <p>FAO: Pearson Mocks Service <br />
            Fretwell Road<br />
            Hellaby Industrial Estate<br />
            Hellaby<br />
            South Yorkshire S66 8HN</p>

          <p>5.1 Returning scripts to Pearson is a centre’s responsibility and at their cost; we recommend that you use a tracked service.</p>
          <p><b>5.2 Please note, any delays in returning scripts to Pearson may result in delays in results being issued.</b></p>

          <h2>6. Timeframes for the services</h2>

          <p>6.1 We will aim to complete marking and upload marks to ResultsPlus within the timeframes published on our website. These are:</p>

          <ul>
            <li>Paper-Based Pearson-Marked – <b>within 21 days</b> of receiving the scripts to our Hellaby site.</li>
            <li>Onscreen Pearson-Marked – <b>within 14 days</b> of papers being submitted onscreen.</li>
          </ul>

          <p>6.2 There may be busy periods in the year when we may not be able to meet the published timeframes, which are an estimate only.</p>
          <p>6.3 Pearson reserves the right to suspend the service (with prior notice) during peak periods.</p>

          <h2>7. Platform for onscreen assessment</h2>

          <p>All GCSE English Onscreen Mock assessments will be taken on our Pearson Assessment Delivery (Modulus) platform. A demonstration of our onscreen platform can be found on the Mocks Service, Onscreen assessment area of our website.</p>
          <p>All GCSE Maths Onscreen Mock assessments will be taken on our partner’s learning platform (Blutick).</p>
          <p>We will contact your in-centre administrative support to discuss the use of the platforms and making them available for your students in time for their exams.</p>
          <p>We will give students access to practice tests prior to their exams so they can practice answering questions on the platform before their mock examination. </p>

          <h2>8. Comparison between onscreen and paper assessments</h2>

          <p>We have worked extensively to keep the onscreen mock examination experience as close as possible to that of the paper based test, but there are some questions where the experience may be different between students who take the examination onscreen and those who do so on paper.</p>
          <p>Whilst the skills required may be different between the two modes of delivery, the knowledge and understanding of the concepts required to successfully complete these questions will remain the same.</p>

          <h2>9. ResultsPlus</h2>

          <p>Please read our <a href="https://qualifications.pearson.com/en/support/Services/ResultsPlus/terms-and-conditions.html">ResultsPlus Terms and Conditions of service</a>, which you will be confirming you agree to as part of accepting the terms and conditions for our Mocks Services.</p>

          <h2>10. Rights and Permissions/ Copyright</h2>

          <p>All materials published by Edexcel are protected by copyright and may not be copied or made available electronically in whole or in part without permission from Pearson. This includes specifications, schemes of work, past question papers, mark schemes, and all other materials.</p>
          <p>(NB) You will be in breach of Pearson Copyright Policy by uploading question papers, mark schemes and examiner reports for the most recent exam sessions (within the last 9 months) as these are under lock until released by Pearson for general use by the public.</p>

          <h2>11. Withdrawal of Service</h2>

          <p>Whilst we continue to operate in unprecedented times, we reserve the right to withdraw this service in line with priorities across the education sector.</p>

          <h2>12. Data protection:</h2>

          <p>Your data will be processed by Pearson in accordance with our <a href="https://www.pearson.com/legal-information/privacy-policy.html">Privacy Policy</a>.</p>

          <h2>13. Case study/ Research:</h2>

          <p>We believe that this service has the potential to help centres improve student outcomes and give students the best possible chance at achieving the grades they deserve in the live exams. We would like to measure the impact of the service with an extensive research study so we may use your data to contact you about taking part. We will be looking to understand the experiences of teachers and students throughout the process and analyse student outcomes.</p>

        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button @click.prevent="agreeAndConfirm" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                <span>Agree & Confirm</span>
            </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
            <button @click.prevent="closeTermsModal" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
            </button>
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
  import Popup from "@/components/Popup";
  export default {
    components: {
      Popup
    },
    props: {
      open: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      agreeAndConfirm() {
        this.$emit('confirmed');
      },
      closeTermsModal() {
        this.$emit('canceled');
      }
    }
  }
</script>
