<template>
  <div>
    <nav class="hidden sm:flex items-center text-sm leading-5 font-medium mb-5 bt-6">
      <a href="#" @click.prevent="cancel" class="text-gray-800 underline hover:text-gray-700 transition duration-150 ease-in-out">Assessment Listings</a>
      <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
      <span class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">{{ assessment.title }}</span>
    </nav>

    <paper-details
      :unmarked-count="unmarkedCount"
      :assessment="assessment"
      :series="series"
      :total-count="pagination.showing.of"
    />

    <h2 class="mt-5 mb-2 font-medium">Candidates</h2>

    <div v-if="seriesIsClosed" class="rounded-md bg-yellow-50 p-4 mb-2">
      <div class="flex items-center text-sm">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          This series has closed, all grades are readonly.
        </div>
      </div>
    </div>

    <div>
      <filters
          @filter="filter"
          :loading="loading"
      />

      <div v-if="! loading" class="flex flex-col mt-4">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full shadow-sm overflow-hidden border rounded-md border-gray-100">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  External ID
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  External ID 2
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  Schedule
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  Fullname
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  Gender
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  DOB
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 uppercase tracking-wider">
                  Grade
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="! sessions.length" class="bg-white">
                <td colspan="20" class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  Sorry, no results found
                </td>
              </tr>
              <tr v-for="session in sessions" :key="session.id" class="bg-white hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ session.candidate.external_id }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ session.candidate.external_id_2 }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ session.schedule_name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ session.candidate.full_name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ session.candidate.gender }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  {{ date(session.candidate.dob) }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-xs leading-5 font-medium text-gray-900">
                  <div v-if="session.endorsement_assessment_grade">
                    {{ session.endorsement_assessment_grade.grade }}
                  </div>

                  <div v-else class="flex space-x-2">
                    <select class="grade-input bg-white focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-10 py-1 border-gray-200 border-l-1 text-gray-900 sm:text-sm" v-model="grades[session.id]">
                      <option v-for="(grade, id) in gradesList" :key="id" :value="id">
                        {{ grade }}
                      </option>
                    </select>

                    <span class="inline-flex rounded-md shadow-sm">
                      <button @click="submitGrade(session.id)" :disabled="! grades[session.id] || session.submitting" class="bg-blue-500 hover:bg-blue-600 inline-flex items-center px-3 py-1.5 border border-transparent text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
                        <loader v-if="session.submitting" class="w-5 text-white" />
                        <svg v-else class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /><!--                    </svg>-->
                        </svg>
                      </button>
                    </span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-8">
            <pagination v-if="!loading"
              @paginate="paginate"
              collection-name="candidates"
              :prev-page="prevPage"
              :next-page="nextPage"
              :page-links="pageLinks"
              :to="pagination.showing.to"
              :from="pagination.showing.from"
              :of="pagination.showing.of"
              :current="pagination.current_page"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex items-center justify-center h-16 border border-gray-100 bg-blue-50 rounded-md mt-4">
      <loader class="w-5 text-blue-500"></loader> <span class="text-blue-500 ml-2 text-xs">loading data please wait</span>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import Pagination from '@/components/Pagination';
import PaginationUtils from "@/utils/PaginationUtils";
import PaperDetails from "@/components/MarkEntry/PaperDetails";
import Filters from "@/components/MarkEntry/Filters";

export default {
  props: ['assessment', 'series'],
  emits: ['cancel'],
  components: {
    PaperDetails,
    Loader,
    Pagination,
    Filters,
  },
  data() {
    return {
      loading: false,
      sessions: [],
      gradesList: [],
      grades: {},
      unmarkedCount: 0,
      filters: {
        sort: '',
        search: '',
      },
      searching: null,
      ...PaginationUtils.data,
    };
  },
  mounted() {
    this.getCandidateSessions();
  },
  computed: {
    seriesIsClosed() {
      return Date.parse(this.series.ends_at) < new Date();
    },
    ...PaginationUtils.computed,
  },
  methods: {
    paginate(page) {
      this.getCandidateSessions(page);
    },
    submitGrade(sessionId) {
      const session = this.sessions.find(sess => sess.id === sessionId);
      const grade = this.grades[sessionId];

      session.submitting = true;

      this.$axios.patch(this.$global.apiEndpoint(`/bookings/${sessionId}/endorsement-grade`), { grade })
          .then(response => {
            this.unmarkedCount--;
            session.endorsement_assessment_grade = { grade: this.gradesList[grade] };
            this.$global.success(response.data);
          })
          .catch(error => {
            this.$global.globalAXIOSErrorHandler(error);
          })
          .finally(() => {
            session.submitting = false;
          });
    },
    date(date) {
      return this.$moment(date).format('DD/MM/YYYY');
    },
    cancel() {
      this.$emit('cancel');
    },
    filter(filters) {
      this.filters = filters;
      this.getCandidateSessions(1);
    },
    getCandidateSessions(page) {
      this.loading = true;
      this.pagination.current_page = page || 1;

      this.$axios.get(this.$global.apiEndpoint(`/assessments/${this.assessment.id}/endorsement-sessions?schedule_series=${this.series.id}&query=${this.filters.search}&order_by=${this.filters.sort}&page=${this.pagination.current_page}`))
        .then(response => {
          this.unmarkedCount = response.data.unmarkedCount;
          this.sessions = response.data.sessions.data;
          this.gradesList = response.data.grades;
          this.pagination = this.$global.paginationData(response, 'sessions');
        })
        .catch(error => {
          this.$global.globalAXIOSErrorHandler(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>
